<template>
    <div class="tag">
        <h3>{{ t("about.brand") }}</h3>
        <p>{{t('about.m-2023-b1')}}</p>
        <p>{{t('about.m-2023-b2')}}</p>
        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/DP-Colour-Dark-BG.svg" class="lesspay"/>
        <p>{{t('about.m-2023-b3')}}</p>
        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/DA-Colour-Light-BG.svg" class="lesspay"/>
        <p>{{t('about.m-2023-b4')}}</p>
        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/DC-Colour-Dark_BG.svg" class="lesspay"/>
        <p>{{t('about.m-2023-b5')}}</p>
        <p>{{t('about.m-2023-b6')}}</p>
        <p>{{t('about.m-2023-b7')}}</p>
        <p>{{t('about.m-2023-b8')}}</p>
    </div>
    <div class="tag">
        <h3>{{ t("about.global") }}</h3>
        <p>{{t('about.m-2023-g1')}}</p>
    </div>
    <div class="tag">
        <h3>{{ t("about.safety") }}</h3>
        <p>{{t('about.m-2023-s1')}}</p>
        <p>{{t('about.m-2023-s2')}}</p>
        <p>{{t('about.m-2023-s3')}}</p>
        <p>{{t('about.m-2023-s4')}}</p>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
const imageUrl2 = "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/about/";

const { t } = useI18n();
</script>