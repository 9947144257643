<template>
    <div class="tag">
        <h3>{{ t("about.brand") }}</h3>
        <p>{{t('about.m-2022-b1')}}</p>
        <img :src="`${imageUrl2}sub-brand_DW.png`" alt="Doo Wealth" class="doowealth"/>
        <p>{{t('about.m-2022-b2')}}</p>
        <!-- <p>{{t('about.m-2022-b3')}}</p> -->
        <p>{{t('about.m-2022-b4')}}</p>
        <p>{{t('about.m-2022-b5')}}</p>
    </div>
    <div class="tag">
        <h3>{{ t("about.global") }}</h3>
        <p>{{t('about.m-2022-g1')}}</p>
    </div>
    <div class="tag">
        <h3>{{ t("about.safety") }}</h3>
        <p>{{t('about.m-2022-s1')}}</p>
        <p>{{t('about.m-2022-s2')}}</p>
        <p>{{t('about.m-2022-s3')}}</p> 
        <p>{{t('about.m-2022-s4')}}</p> 
    </div>
    <!--div class="tag">
        <h3>{{ t("about.product") }}</h3>
        <p>{{t('about.m-2022-p1')}}</p>
        <p>{{t('about.m-2022-p2')}}</p>
        <p>{{t('about.m-2022-p3')}}</p>
    </div-->
</template>

<script setup>
import { useI18n } from 'vue-i18n';
const imageUrl2 = "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/about/new-milestone/";


const { t } = useI18n();
</script>