<template>
    <section id="lang-selector">
        <div class="component">
            <div class="wrapper">
                <img class="globe" src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Language.svg"
                    alt="language-img">
                <p class="select-lang" v-t="'common.language-selection'"></p>
                <div class="dropdown">
                    <div class="wrapper-content">
                        <p class="selected" v-t="`common.${locale}`"></p>
                        <img class="arrow-down"
                            src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Dropdown.svg"
                            alt="arrow-down">
                    </div>

                    <div class="dropdown-content">
                        <div v-for="(lang, index) in langList" :key="index">
                            <a v-if="lang == locale" @click="switchLanguage(lang)" class="lang active"
                                v-html="$t(`common.${lang}`)"></a>
                            <a v-else @click="switchLanguage(lang)" class="lang" v-html="$t(`common.${lang}`)"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter, useRoute } from 'vue-router';

let langList = ['en', 'sc', 'tc'];
const router = useRouter()
const route = useRoute()
const { locale } = useI18n()
const switchLanguage = (lang) => {
    document.documentElement.lang = lang
    router.push(`/${lang}${route.path.substring(3)}`)
}


</script>


<style scoped lang="less">
section {
    &#lang-selector {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
    }

    .component {
        background-color: #161414;
        min-height: object-fit;

        .wrapper {
            display: flex;
            align-items: center;
            justify-content: end;
            height: 100%;
            padding: 6px 0px 6px 0;
            max-width: 1600px;

            @media only screen and (max-width:1600px) {
                max-width: none;
                padding-right: 16px;
                padding-left: 16px;
            }

            margin: auto;

            .globe {
                width: 16px;
                height: 16px;
            }

            .select-lang {
                padding-left: 9.3px;
                padding-right: 6px;

                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: normal;
                color: #fff;
                margin: 0;
            }

            .dropdown {
                position: relative;

                .wrapper-content {
                    .selected {

                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.43;
                        letter-spacing: normal;
                        color: #fff;
                        margin: 0;

                    }

                    .arrow-down {
                        width: 16px;
                        height: 16px;
                    }

                    display: flex;
                    align-items: center;
                }

                .wrapper-content:hover {
                    cursor: pointer;
                }
            }

            .dropdown-content {
                display: none;
                position: absolute;
                background-color: #000018;
                min-width: 160px;
                top: 19px;
                right: 0;
                box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                z-index: 1;
                min-width: 125px;
                text-align: left;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                letter-spacing: normal;
                color: #fff;
            }

            .dropdown:hover .dropdown-content {
                display: block;
            }

            .dropdown-content {
                div {
                    color: #ffffff;
                    padding: 12px 16px;
                    display: block;

                    a {
                        cursor: pointer;
                        display: block;
                        width: 100%;

                        &:hover,
                        &:focus,
                        &.active {
                            color: red;
                        }
                    }
                }
            }
        }

    }
}

@media screen and (max-width: 1100px) {

    section {
        &#lang-selector {
            display: none;
        }
    }
}
</style>