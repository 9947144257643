<template>
    <div class="tag">
        <h3>{{t("about.brand")}}</h3>
        <p>{{t('about.m-2017-b1')}}</p>
        <img :src="`${imageUrl2}sub-brand_DC.png`" class="dooclearing"/>
    </div>
    <div class="tag">
        <h3>{{t("about.global")}}</h3>
        <p>{{t('about.m-2017-g1')}}</p>
    </div>
    <!--div class="tag">
        <h3>{{t("about.product")}}</h3>
        <p>{{t('about.m-2017-p1')}}</p>
        <p>{{t('about.m-2017-p2')}}</p>
    </div-->
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const imageUrl2 = "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/about/new-milestone/";

const { t } = useI18n();
</script>
