<template>
  <Teleport to="head">
    <title>{{ $t("seo_title") }} | {{ $t("seo_title2") }}</title>
    <meta name="description" :content="$t('seo_desc')" />
    <meta name="keyword" :content="$t('seo_keyword')" />
  </Teleport>
  <div id="business-overview" class="header-gap bo">
    <div class="sub-banner">
      <div class="container">
        <h1 v-html="$t('business-overview.banner-title')"></h1>
        <p v-html="$t('business-overview.banner-desc')"></p>
      </div>
    </div>
    <div class="six-major-business-lines">
      <h2 v-html="$t('business-overview.six-major-business-lines-title')"></h2>
      <p>{{ $t("business-overview.six-major-business-lines-desc") }}</p>
      <div class="business-categories">
        <img :src="`https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/business_overview/businessoverview_bg${locale === 'en' ? '_en' : locale === 'tc' ? '_tc' : ''}.png`" class="web web-image" />
        <img :src="`https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/business_overview/businessoverview_bg_mb${locale === 'en' ? '_en' : locale === 'tc' ? '_tc' : ''}.png`" class="mobile mobile-image"/>
      </div>
      <!-- <div class="video mobile">
        <iframe src="https://www.youtube.com/embed/0-WjKq-BSHQ" width="560" height="315" frameborder="0"
          allowfullscreen></iframe>
      </div> -->
      <div class="wrapper">
        <div class="container">
          <div class="row">
            <div class="column">
              <h2>{{ $t("business-overview.brokerage") }}</h2>
              <p><i></i> {{ $t("business-overview.brokerage-desc") }}</p>
              <RouterLink :to="`/${$i18n.locale}/brokerage`" v-t="'business-overview.learn-more'"></RouterLink>
            </div>
            <div>
              <img
                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/business_overview/businessOverview_brokerage-new.png" />
            </div>
          </div>
          <div class="row">
            <div class="column">
              <h2>{{ $t("business-overview.wealth-management") }}</h2>
              <p>
                <i></i> {{ $t("business-overview.wealth-management-desc") }}
              </p>
              <RouterLink :to="`/${$i18n.locale}/wealthmanagement`" v-t="'business-overview.learn-more'"></RouterLink>
            </div>
            <div>
              <img
                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/business_overview/businessOverview_wealth-management.png" />
            </div>
          </div>
          <div class="row">
            <div class="column">
              <h2>{{ $t("business-overview.property") }}</h2>
              <p><i></i> {{ $t("business-overview.property-desc") }}</p>
              <RouterLink :to="`/${$i18n.locale}/property`" v-t="'business-overview.learn-more'"></RouterLink>
            </div>
            <div>
              <img src="https://cdn.dootech.io/doo/business_overview/businessoverview_property.png" />
            </div>
          </div>
          <div class="row">
            <div class="column">
              <h2>{{ $t("business-overview.payment-exchange") }}</h2>
              <p><i></i> {{ $t("business-overview.payment-exchange-desc") }}</p>
              <RouterLink :to="`/${$i18n.locale}/paymentexchange`" v-t="'business-overview.learn-more'"></RouterLink>
            </div>
            <div>
              <img
                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/business_overview/businessOverview_payment-exchange-new.png" />
            </div>
          </div>
          <div class="row">
            <div class="column">
              <h2>{{ $t("business-overview.fintech") }}</h2>
              <p><i></i> {{ $t("business-overview.fintech-desc") }}</p>
              <RouterLink :to="`/${$i18n.locale}/fintech`" v-t="'business-overview.learn-more'"></RouterLink>
            </div>
            <div>
              <img
                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/business_overview/businessOverview_fintech-new.png" />
            </div>
          </div>
          <div class="row">
            <div class="column">
              <h2>{{ $t("business-overview.financial-education") }}</h2>
              <p>
                <i></i> {{ $t("business-overview.financial-education-desc") }}
              </p>
              <RouterLink :to="`/${$i18n.locale}/financialeducation`" v-t="'business-overview.learn-more'"></RouterLink>
            </div>
            <div>
              <img
                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/business_overview/businessOverview_financial-education-new.png" />
            </div>
          </div>
          <div class="row">
            <div class="column">
              <h2>{{ $t("business-overview.health-care") }}</h2>
              <p><i></i> {{ $t("business-overview.health-care-desc") }}</p>
              <RouterLink :to="`/${$i18n.locale}/healthcare`" v-t="'business-overview.learn-more'"></RouterLink>
            </div>
            <div>
              <img
                src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/business_overview/businessOverview_health-care-new.png" />
            </div>
          </div>
          <div class="row">
            <div class="column">
              <h2>{{ $t("business-overview.consulting") }}</h2>
              <p><i></i> {{ $t("business-overview.consulting-desc") }}</p>
              <RouterLink :to="`/${$i18n.locale}/consult`" v-t="'business-overview.learn-more'"></RouterLink>
            </div>
            <div>
              <img src="https://cdn.dootech.io/doo/business_overview/businessoverview_consulting.png" />
            </div>
          </div>
          <div class="row">
            <div class="column">
              <h2>{{ $t("business-overview.cloud") }}</h2>
              <p><i></i> {{ $t("business-overview.cloud-desc") }}</p>
              <RouterLink :to="`/${$i18n.locale}/cloud`" v-t="'business-overview.learn-more'"></RouterLink>
            </div>
            <div>
              <img src="https://cdn.dootech.io/doo/business_overview/businessoverview_cloud.png" />
            </div>
          </div>
          <div class="row">
            <div class="column">
              <h2>{{ $t("business-overview.digital-marketing") }}</h2>
              <p>
                <i></i> {{ $t("business-overview.digital-marketing-desc") }}
              </p>
              <RouterLink :to="`/${$i18n.locale}/digital-marketing`" v-t="'business-overview.learn-more'"></RouterLink>
            </div>
            <div>
              <img src="https://cdn.dootech.io/doo/business_overview/businessoverview_digital-marketing.png" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <BOContactUs bocontent="business-overview" />
  </div>
  <Modals />
</template>

<script setup>
import "../less/style.css";
import Modals from "@/components/Modals/ModalMain.vue";
import BOContactUs from "@/components/BOContactUs.vue";
import { useI18n } from "vue-i18n";

const { locale } = useI18n();
</script>

<style lang="less">
@import "../less/mediaQuery.less";

#business-overview {
  .sub-banner {
    background: url("https://cdn.dootech.io/doo/business_overview/banner_businessOverview_new.png") no-repeat center top;

    @media @mobile-screen {
      background: url("https://cdn.dootech.io/doo/business_overview/banner_businessOverview_mobile-new.png") no-repeat center top;
    }

    .container {
      @media only screen and (max-width: 1500px) and (min-width: 835px) {
        max-width: 40%;
        margin: 0;
      }
    }
  }

  .six-major-business-lines {
    padding: 0 16px;

    >h2 {
      font-size: 48px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #3e3a39;
      max-width: 1000px;
      margin: 0 auto 24px;

      @media @mobile-screen {
        font-size: 28px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #3e3a39;
      }
    }

    >p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #3e3a39;
      max-width: 788px;
      margin: 0 auto;
      padding: 0 16px;

      @media @mobile-screen {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #3e3a39;
        margin-bottom: 40px;
      }
    }

    .wrapper {
      .container {
        @media @mobile-screen {
          width: initial;
          margin: 0 -16px;
        }
      }
    }

    .business-categories {
      position: relative;
      z-index: -1;
      max-width: 2560px;
      margin: -5% auto 160px;

      @media @mobile-screen {
        margin: 0 0 80px 0;
      }

      img {
        max-width: 100%;
        width: 100%;
      }

      .mobile-image {
        object-fit: cover;
        height: 385px;
      }

      .business-categories-content {
        position: absolute;
        max-width: 1200px;
        max-height: 700px;
        width: 100%;
        height: 100%;

        @media @mobile-screen {
          width: 375px;
          height: 385px;
        }

        left: 50%;
        top: 100%;
        transform: translate(-50%, -100%);
      }

      h3 {
        position: absolute;
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #3e3a39;
      }
    }

    .video {
      margin-bottom: 80px;

      iframe {
        width: 100%;
        height: 193px;
      }
    }
  }
}
</style>
