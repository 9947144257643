<template>
    <section id="footer-banner">
        <div class="footer-img-top" v-if="isMobile == false">
            <img class="adelaide-footer" src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Footer_new_1.png"
                alt="adelaide-footer">
        </div>
        <div class="footer-img-top" v-else>
            <img class="adelaide-footer" src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Footer-mobile-new-2.png"
                alt="adelaide-footer">
        </div>

    </section>
</template>

<script>

export default {
    data() {
        return {
            isMobile: false,
        }
    },
    methods: {
        checkMobile() {
            if (window.innerWidth <= 835) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
    },
    mounted() {
        this.checkMobile()
        window.addEventListener("resize", this.checkMobile);
    }
}
</script>

<style lang="less" scoped >
#footer-banner {
    .footer-img-top {
        display: flex;
        justify-content: center;
    }
    @media only screen and (max-width:834px) {
        .adelaide-footer {
            max-height: 385px;
        }
    }
    
}
</style>