<template>
  <Teleport to="head">
    <meta name="description" :content="$t('aufc.seo.desc')">
    <meta name="keyword" :content="$t('aufc.seo.keyword')">>
  </Teleport>
  <div id="aufc-page">
    <div class="sticky-header">
      <LanguageBar v-if="isMobile == false"></LanguageBar>
      <Header />
    </div>
    <div class="padding-sticky-header"></div>
    <Banner />
    <Highlight />
    <Goals />
    <Community />
    <div id="highlights" class="scrollMargin">
      <div class="center-title">
        <h2>{{ t('mu.third_title') }}</h2>
        <p>{{ t('aufc.partnership-desc') }}</p>
      </div>
      <ThreeBlogRow />
    </div>
    <Discover />
  </div>
  <Modals />
</template>
  
<script setup>
import { ref, onMounted, onUnmounted, watch, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
import "../less/style.css"
import LanguageBar from '@/components/Model/LanguageBar.vue'
import Header from "../components/AUFC/Header"
import Banner from "../components/AUFC/Banner"
import Modals from '@/components/Modals/ModalMain.vue'
import Highlight from '@/components/AUFC/Highlight.vue'
import Discover from '@/components/AUFC/Discover.vue'
import Community from '@/components/AUFC/Community.vue'
import ThreeBlogRow from "../components/AUFC/ThreeBlogRow"
import Goals from '@/components/AUFC/Goals.vue'
import { getLocation } from "@/api/location";
import { useRouter, useRoute } from 'vue-router';

const router = useRouter()
const route = useRoute()

let isMobile = ref(false);
let currentCountry = "";

onMounted(async () => {
  const location = await getLocation();

  let defaultLang = 'en';

  if (location.code == 0) {
    currentCountry = location.data.country_code

    const locationToLang = {
      CN: "sc",
      TW: "tc",
      HK: 'sc',
    };

    const keys = Object.keys(locationToLang);

    if (keys.includes(currentCountry)) {
      defaultLang = locationToLang[currentCountry]
    }
  }

  const checkMobile = () => {
    if (window.innerWidth <= 835) {
      isMobile.value = true;
    } else {
      isMobile.value = false;
    }
  };

  checkMobile(); // Initial check when component is mounted

  window.addEventListener("resize", checkMobile);

  onUnmounted(() => {
    window.removeEventListener("resize", checkMobile);
  });
});

</script>

<style lang="less">
html {
  -scroll-behavior: smooth;
}

#aufc-page {
  font-family: Gravity;

  h1,
  h2,
  h3 {
    font-family: "Staatliches-Regular";
  }

  .container {
    max-width: 1404px;
  }
}
</style>
