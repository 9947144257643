<template>
    <div class="tag">
        <h3>{{ t("about.brand") }}</h3>
        <p>{{ t('about.m-2014-b1') }}</p>
        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/DG@latest.png"
            class="doogroup" />
        <p>{{ t('about.m-2014-b2') }}</p>
    </div>
    <div class="tag">
        <h3>{{ t("about.global") }}</h3>
        <p>{{ t('about.m-2014-g1') }}</p>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>