<template>
    <div class="mascot-bg" ref="mascotView" v-if="mascotViewRef">
        <div class="container">
            <div class="mascot">
                <div class="mascot-box">
                    <div class="mascot-header">
                        <h2 class="mascot-title" v-html="t('mascot.title')"></h2>
                    </div>
                    <div :class="`mascot-body ${locale == 'sc' || locale == 'tc' ? 'sc-body' : 'en-body'}`">
                        <h2 class="mascot-subtitle" v-html="t('mascot.subtitle')"></h2>
                        <p class="mascot-desc" v-html="t('mascot.desc')"></p>
                        <a class="mascot-btn"
                            :href="`https://blog.doo.com/${locale == 'sc' || locale == 'tc' ? 'sc/' : 'en/'}news/doo-group-10th-anniversary-meet-our-mascot-dooie/`">{{
                                $t('banner.learn-more') }}</a>
                    </div>
                </div>
                <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/mascot/icon-close.png"
                    class="close-icon" @click="handleClose()" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n()
const mascotViewRef = ref(true);

const handleClose = () => {
    mascotViewRef.value = false;
    document.body.style.overflow = "unset"
}

onMounted(() => {
    document.body.style.overflow = "hidden"
})

</script>

<style lang="less">
@import '../../less/mediaQuery.less';

.mascot-bg {
    position: absolute;
    z-index: 11;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;

    .container {
        position: relative;
        width: 100vw;
        height: 100vh;

        .mascot {
            position: absolute;
            top: 50%;
            left: 48%;
            transform: translate(-50%, -60%);
            background-image: url(https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/mascot/mascot.png);
            background-repeat: no-repeat;
            width: 543px;
            height: 564px;
            animation: ZoomInOut 3s ease-in-out infinite;
            transition: transform 0.3s ease-in-out;

            @media @tablet-screen {
                background-image: url(https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/mascot/mascot-mb.png);
                width: 339px;
                height: 367px;
                left: 46%;
            }

            .mascot-box {
                position: relative;
                width: 100%;
                height: 100%;

                .mascot-header {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(30%, -95%);

                    @media @tablet-screen {
                        transform: translate(35%, -95%);
                    }
                }

                .mascot-body {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 65%;

                    &.sc-body {
                        transform: translate(-42%, 30%);

                        @media @tablet-screen {
                            transform: translate(-42%, 20%);
                            width: 71%;

                        }
                    }

                    &.en-body {
                        transform: translate(-42%, 15%);

                        @media @tablet-screen {
                            transform: translate(-42%, 10%);
                        }
                    }
                }
            }


        }
    }

    .mascot-title {
        font-size: 28px;
        font-weight: 700;
        text-align: left;
        color: #fff;

        @media @tablet-screen {
            font-size: 20px;
        }
    }

    .mascot-subtitle {
        font-size: 28px;
        font-weight: 600;
        text-align: center;
        color: #3e3a39;

        @media @tablet-screen {
            font-size: 20px;
        }
    }

    .mascot-desc {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        color: #3e3a39;
        margin: 12px 0 24px 0;

        @media @tablet-screen {
            font-size: 14px;
            margin: 8px 0 12px 0;
        }
    }

    .mascot-btn {
        display: inline-block;
        padding: 16px 0;
        text-align: center;
        background-color: #e03323;
        font-size: 16px;
        font-weight: normal;
        text-align: center;
        color: #FFF;
        width: 180px;

        @media @tablet-screen {
            width: 119px;
            font-size: 14px;
            padding: 8px 0;
        }
    }

    .close-icon {
        transform: translate(110%, 50%);
        cursor: pointer;

        @media @tablet-screen{
            transform: translate(50%, 50%);
        }
    }

}

@keyframes ZoomInOut {
    0% {
        transform: translate(-50%, -60%) translateY(0);
    }

    50% {
        transform: translate(-50%, -60%) translateY(-6px);
    }

    100% {
        transform: translate(-50%, -60%) translateY(0);
    }
}
</style>