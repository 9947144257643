import axios from "axios";

const baseUrl = "/location";

export const getLocation = async () => {
  try {
    const payload = await axios({
      method: "GET",
      url: "https://frontapi.finpoints.tech",
      // withCredentials: true,
    });

    const data = {
      code: 0,
      data: {
        country: payload.data.data.iso_country_code,
        country_code: payload.data.data.iso_country_code,
      },
    };
    return data;
  } catch (e) {
    return e;
  }
};
