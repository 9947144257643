<template>
  <div>
    <div class="header-box">
      <template v-for="(link, index) in headerLink" :key="link.title">
        <template v-if="index === Math.ceil(headerLink.length / 2)">
          <img class="header-logo" :src="`${ImageUrl}header-logo.png`" alt="DOO GROUP | AUFC" />
          <div :class="'header-wrapper ' + (contentId === link.href ? 'menu-selected' : '')">
            <a class="header-link" @click="scrollToView(link.href)">
              {{ t('aufc.' + (link.href)) }}
            </a>
            <div class="underline"></div>
          </div>
        </template>
        <template v-else>
          <div :class="'header-wrapper ' + (contentId === link.href ? 'menu-selected' : '')">
            <a class="header-link" @click="scrollToView(link.href)">
              {{ t('aufc.' + (link.href)) }}
            </a>
            <div class="underline"></div>
          </div>
        </template>
      </template>
    </div>
    <nav class="mobile-nav-bar mobile-header-box">
      <input type="checkbox" style="display: none" ref="mobileMenuCheckBox" id="menuBtn" />
      <img :src="`${ImageUrl}Logo_MUxAU_mobile.png`" />
      <span @click="triggerMenu">
        <img src="@/assets/Icon_Menu.png" class="hamburger" alt="hamburger icon" />
      </span>
      <div class="mobile-nav-content">
        <template v-if="languageMenu">
          <div>
            <img src="@/assets/MuLanding/icon-arrow-right-red.png" alt="" />
            <p class="language-text">{{ t('language') }}</p>
          </div>
          <div>
            <RouterLink :class="$i18n.locale === 'en' ? `menu-selected language-option` : `language-option`"
              @click="changeLanguage('en')" :to="`/en/partnership/aufc`" :selected="`${$i18n.locale === 'en'}`"
              v-t="'common.en'" page="home">
            </RouterLink>
          </div>
          <!-- <div>
            <RouterLink :class="$i18n.locale === 'sc' ? `menu-selected language-option` : `language-option`"
              @click="changeLanguage('sc')" :to="`/sc/partnership/aufc`" :selected="`${$i18n.locale === 'sc'}`"
              v-t="'common.sc'" page="home">
            </RouterLink>
          </div>
          <div>
            <RouterLink :class="$i18n.locale === 'tc' ? `menu-selected language-option` : `language-option`"
              @click="changeLanguage('tc')" :to="`/tc/partnership/aufc`" :selected="`${$i18n.locale === 'tc'}`"
              v-t="'common.tc'" page="home">
            </RouterLink>
          </div> -->
        </template>
        <template v-else>
          <template v-for="(link, index) in headerLink" :key="link.title">
            <div>
              <img :src="RedArrowRight" alt="" />
              <a :class="contentId === link.href ? 'menu-selected' : ''" @click="triggerMenu(e, link.href)">
                {{ index == 2 ? (t('aufc.' + (link.href))) : (t('aufc.' + (link.href))) }}
              </a>
            </div>
          </template>
          <hr />
          <div class="mobile-language-row" @click="selectLanguage">
            <img src="@/assets/MuLanding/mobile/globe-icon.png" alt="globe" class="globe" />
            <p>{{ t('language') }}:</p>
            <p>{{ $i18n.locale === 'en' ? t('common.en') : $i18n.locale === 'sc' ? t('common.sc') : t('common.tc') }}</p>
            <img src="@/assets/MuLanding/mobile/icon-arrow-right.png" alt="arrow down" />
          </div>
        </template>
      </div>
    </nav>
  </div>
</template>
  
<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

import { useI18n } from 'vue-i18n';
import RedArrowRight from "@/assets/MuLanding/icon-arrow-right-red.png";
import { useRouter, useRoute } from 'vue-router';

const ImageUrl = "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/aufc/"

const router = useRouter()
const route = useRoute()

const { t } = useI18n();

const headerLink = [
  {
    href: 'aufc-page',
  },
  {
    href: 'highlight',
  },
  {
    href: 'goals',
  },
  {
    href: 'community',
  },
];

const target = [...headerLink].map((item) => item.href).reverse();

//console.log("target", target)

const contentId = ref('aufc-page');
let position = ref([]);

const mobileMenuCheckBox = ref(null);
const languageMenu = ref(false);

onMounted(() => {
  window.addEventListener('scroll', () => {
    position.value = [];
    if (typeof document !== 'undefined') {
      target.forEach((item, index) => {
        let offsetTop = document.getElementById(item).offsetTop - 2;
        position.value.push(offsetTop);
        //console.log("position", position)
      });
    }
    handleScroll();
  });
});

const handleScroll = () => {
  if (typeof window !== 'undefined') {
    const currentPosition = window.scrollY;
    //console.log("currentPosition", currentPosition)
    for (let i = 0; i < position.value.length; i++) {
      if (currentPosition >= position.value[i]) {
        contentId.value = target[i];
        break;
      }
    }
  }
};

const scrollToView = (element) => document.getElementById(element).scrollIntoView();

const triggerMenu = (e, element) => {
  console.log("element", element)

  if (element) {
    scrollToView(`${element}`)
  }
  if (mobileMenuCheckBox.value) {
    mobileMenuCheckBox.value.checked = !mobileMenuCheckBox.value.checked;
    // always show header link
    if (mobileMenuCheckBox.value.checked) languageMenu.value = false;
  }
};

const selectLanguage = () => {
  languageMenu.value = true;
};

const changeLanguage = (newLanguage) => {
  triggerMenu();
  router.push(`/${newLanguage}${route.path.substring(3)}`)
};

</script>

<style lang="less">
#aufc-page {
  section#lang-selector {
    @media only screen and (min-width:835px) {
      display: block !important;
    }
  }
}
</style>