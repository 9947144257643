<template>
  <Teleport to="head">
    <title>{{ $t("seo_title") }} | {{ $t("seo_title2") }}</title>
    <meta name="description" :content="$t('seo_desc')" />
    <meta name="keyword" :content="$t('seo_keyword')" />
  </Teleport>
  <div class="global-office header-gap">
    <div class="sub-banner">
      <div class="container">
        <h1 v-html="$t('about.global-office-title')"></h1>
        <p v-html="$t('about.global-office-sub-title')"></p>
      </div>
    </div>
    <GlobalPresence></GlobalPresence>
    <div class="global-operations-centers">
      <div class="container">
        <h2 v-html="$t('about.global-operations-centers')"></h2>
        <div class="videos">
          <div v-for="(item, index) in allVideo" :key="index" class="vpop" :data-type="`${item}`" data-autoplay='true'>
            <img :src="`https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/about/${item}.png`" class="web" />
            <img :src="`https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/about/${item}_mobile.png`"
              class="mobile" />
            <div class="icon-play">
              <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/about/icon-common-play.svg" />
              <span v-html="$t(`about.${item}`)"></span>
            </div>
          </div>
          <div id="video-popup-overlay"></div>
          <div id="video-popup-container">
            <div id="video-popup-iframe-container">
              <div id="video-popup-close" class="fade">&#10006;</div>
              <iframe id="video-popup-iframe" src="" width="100%" height="100%" frameborder="0"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalPresence from '../components/LandingPage/GlobalPresence.vue';
import { onMounted } from 'vue'

export default {
  name: 'GlobalOffice',
  components: {
    GlobalPresence,
  },
  data() {
    return {
      allVideo: ['hk', 'uk', 'sg', 'jlt', 'ems'],
    };
  },
  setup() {
    onMounted(() => {
      const videoPopupOverlay = document.getElementById('video-popup-overlay');
      const videoPopupIframeContainer = document.getElementById('video-popup-iframe-container');
      const videoPopupContainer = document.getElementById('video-popup-container');
      const videoPopupClose = document.getElementById('video-popup-close');
      const videoPopupIframe = document.getElementById('video-popup-iframe');
      const vpop = document.querySelectorAll('.vpop');

      const openVideoPopup = (e) => {
        e.preventDefault();

        videoPopupOverlay.style.display = 'block';
        videoPopupIframeContainer.style.display = 'block';
        videoPopupContainer.style.display = 'flex';
        videoPopupClose.style.display = 'block';

        let videosrc = '';
        let autoplay = '';

        switch (e.target.parentNode.getAttribute('data-type')) {
          case 'hk':
            videosrc = 'https://www.youtube.com/embed/oWzYJah2ijU';
            break;
          case 'uk':
            videosrc = 'https://www.youtube.com/embed/oKMsvlmipvA';
            break;
          case 'sg':
            videosrc = 'https://www.youtube.com/embed/B2rLgvMrdhY';
            break;
          case 'jlt':
            videosrc = 'https://www.youtube.com/embed/SLWNWXCygmw';
            break;
          case 'ems':
            videosrc = 'https://www.youtube.com/embed/gen6z9MBp80';
            break;
        }

        if (e.target.getAttribute('data-autoplay') === 'true') autoplay = '?autoplay=1';

        videoPopupIframe.src = videosrc + autoplay;

      };

      const closeVideoPopup = () => {
        videoPopupIframeContainer.style.display = 'none';
        videoPopupContainer.style.display = 'none';
        videoPopupClose.style.display = 'none';
        videoPopupOverlay.style.display = 'none';
        videoPopupIframe.src = '';
      };

      const vpopElements = () => {
        vpop.forEach((element) => {
          element.addEventListener('click', openVideoPopup);
        });

        const closePopupElements = document.querySelectorAll('#video-popup-close, #video-popup-overlay');
        closePopupElements.forEach((element) => {
          element.addEventListener('click', closeVideoPopup);
        });
      };

      vpopElements();

      return {
        openVideoPopup,
        closeVideoPopup,
        vpopElements,
      };
    })
  },
};
</script>


<style scoped lang="less">
@import '../less/mediaQuery.less';

.global-office {
  .sub-banner {
    background: url('https://cdn.dootech.io/doo/about/global_office.png') no-repeat center top;

    @media @mobile-screen {
      background: url('https://cdn.dootech.io/doo/about/global_office_mobile.png') no-repeat center top;
    }

    .container {
      @media only screen and (max-width:1500px) and (min-width:835px) {
        max-width: 40%;
        margin: 0;
      }
    }
  }

  .container {
    text-align: left;
    padding: 32px 16px 0;

  }

  .videos {
    display: grid;
    // grid-template-columns: repeat(6, minmax(0, 1fr));
    justify-content: center;
    gap: 24px;

    .vpop:nth-child(1) {
      display: grid;
      grid-column: 1 / span 2;
    }

    .vpop:nth-child(2) {
      display: grid;
      grid-column: 3 / span 2;
    }

    .vpop:nth-child(3) {
      display: grid;
      grid-column: 5 / span 2;
    }

    .vpop:nth-child(4) {
      display: grid;
      grid-column: 2 / span 2;
    }

    .vpop:nth-child(5) {
      display: grid;
      grid-column: 4 / span 2;
    }

    @media @mobile-screen {
      display: block;
    }

    >div {
      position: relative;
      cursor: pointer;

      @media @mobile-screen {
        margin-bottom: 16px;
      }

      >img {
        max-width: 100%;

        @media @mobile-screen {
          width: 100%;
          height: 240px;
          object-fit: cover;
        }
      }
    }

    .icon-play {
      position: absolute;
      left: 32px;
      bottom: 32px;
      text-align: center;

      @media @mobile-screen {
        left: 16px;
        bottom: 16px;
      }

      img {
        width: 56px;
        height: 56px;
        display: block;
        margin-bottom: 16px;

        @media @mobile-screen {
          width: 48px;
          height: 48px;
        }
      }

      span {
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.29;
        letter-spacing: normal;
        color: #fff;

        @media @mobile-screen {
          font-size: 28px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          letter-spacing: normal;
        }
      }
    }


  }

  .global-operations-centers {
    padding-bottom: 160px;

    @media @mobile-screen {
      padding-bottom: 80px;

    }

    h2 {
      font-size: 48px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #3e3a39;
      margin-bottom: 80px;

      @media @mobile-screen {
        font-size: 28px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #3e3a39;
        margin-bottom: 32px;
      }
    }
  }

  #video-popup-container {
    display: none;
    position: fixed;
    z-index: 996;
    width: 90%;
    height: 100vh;
    top: 0;
    left: 0;
    margin-left: 5%;
    justify-content: center;
    align-items: center;
  }

  #video-popup-close {
    cursor: pointer;
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 999;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    text-align: center;
    font-size: 12px;
    background-color: #000;
    line-height: 25px;
    color: #fff;
  }

  #video-popup-iframe-container {
    position: absolute;
    z-index: 997;
    width: 100%;
    padding-bottom: 56.25%;
    border: 2px solid #000;
    border-radius: 2px;
    background-color: #000;
  }

  #video-popup-iframe {
    z-index: 998;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #000;
  }

  #video-popup-overlay {
    display: none;
    position: fixed;
    z-index: 995;
    top: 0;
    background-color: #000;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    margin: 0 -16px;
  }

  #video-popup-close:hover {
    color: #DE0023;
  }
}
</style>
