<template>
  <section id="discover">
    <div class="container">
      <h2 v-html="t('aufc.discover')"></h2>
      <p>{{ t("aufc.discover-desc") }}</p>
      <div class="social-media">
        <!-- <a v-for="item in socialMedia" 
            :key="item" :class="`${item}`"
            :href="`
              ${item == 'instagram' ? 'https://www.instagram.com/doo_group/' : ''}
              ${item == 'facebook' ? 'https://www.facebook.com/DooGroup' : ''}
              ${item == 'linkedin' ? 'https://www.linkedin.com/company/doo-group-limited/' : ''}
              ${item == 'youtube' ? 'https://www.youtube.com/@doogroup6728' : ''}
            `"
          >
            <img :src="`${ImageUrl}image_${item}.png`" class="bg"/>
            <img :src="`${ImageUrl}icon_${item}.png`" class="icon" />
            <img :src="`${ImageUrl}icon_${item}_hover.png`" class="icon hover" />
          </a> -->
        <a v-for="item in socialMedia" :href="item.link" :class="item.name">
          <img :src="`${ImageUrl}icon_${item.name}_hover.png`" class="social_icon" />
          <img :src="`${ImageUrl}icon_${item.name}.png`" class="social_icon hover" />
          <p>{{ item.name }}</p>
          <p id="triangle-white"></p>
          <p id="triangle-red"></p>
        </a>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";

const ImageUrl =
  "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/aufc/";

const { t } = useI18n();
const socialMedia = [
  { name: "instagram", link: "https://www.instagram.com/doo_group/" },
  { name: "facebook", link: "https://www.facebook.com/DooGroup" },
  { name: "linkedin", link: "https://www.linkedin.com/company/doo-group-limited/" },
  { name: "youtube", link: "https://www.youtube.com/@doogroup6728" },
];

// onMounted(() => {
//   handleWindowChangeSize();
//   window.addEventListener("resize", handleWindowChangeSize);
// });

// onUnmounted(() => {
//   window.removeEventListener("resize", handleWindowChangeSize);
// });
</script>

<style lang="less">
@import "@/less/mediaQuery.less";

#discover {
  padding-bottom: 120px;

  @media @mobile-screen {
    padding-bottom: 200px;
  }

  h2 {
    font-size: 76px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: left;
    color: #3e3a39;
    margin-bottom: 24px;
    text-align: center;

    @media @mobile-screen {
      font-size: 28px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      text-align: left;
      color: #3e3a39;
      margin-bottom: 16px;
      text-align: center;
    }
  }

  .container {
    >p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #3e3a39;
      max-width: 580px;
      margin: 0 auto 80px;

      @media @mobile-screen {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #3e3a39;
        margin-bottom: 40px;
      }
    }
  }

  .social-media {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;

    @media @mobile-screen {
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }

    a {
      display: block;
      position: relative;
      flex-direction: column;

      &:hover {
        background-color: #e03323;

        p {
          color: #fff;
        }

        .social_icon {
          display: none;
        }

        .hover {
          display: block;
        }

      }

      .social_icon {
        display: block;
        margin: 56px auto 0;
        width: 68px;
        height: 68px;
      }

      .hover {
        display: none;
      }

      p {
        margin-top: 16px;
        font-family: "Staatliches-Regular";
        font-size: 32px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #3e3a39;
      }

      #triangle-red {
        position: relative;
        float: right;
        width: 0;
        height: 0;
        border-bottom: 36px solid #e03323;
        border-left: 36px solid transparent;
      }

      #triangle-white {
        position: absolute;
        right: 0;
        bottom: 0;
        // float: right;
        width: 0;
        height: 0;
        // background-color: aliceblue;
        border-bottom: 46px solid #fff;
        border-left: 46px solid transparent;
      }
    }

    // a {
    //   position: relative;

    //   img {
    //     max-width: 100%;
    //   }

    //   &:hover {
    //     .icon {
    //       display: none;

    //       &.hover {
    //         display: block;
    //       }
    //     }
    //   }

    //   .icon {
    //     position: absolute;
    //     left: 50%;
    //     top: 50%;
    //     translate: -50% -50%;
    //     max-width: 65.4px;

    //     @media @mobile-screen {
    //       // width: 8vw;
    //       min-width: 30.8px;
    //     }

    //     &.hover {
    //       display: none;
    //     }
    //   }
    // }
  }
}
</style>
