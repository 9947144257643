<template>
    <div class="landing-banner center-banner" data-aos="fade-up">
      <h2 class="center-banner-title">
        <span v-if="$i18n.locale === 'en'" class="center-title-1">{{ t("mu.center_title_1") }}</span>
        <span v-if="$i18n.locale === 'en'" class="center-title-2">{{ t("mu.center_title_2") }}</span>
        <span v-else class="landing-title-cn-1">{{ t("mu.center_title_1") }}</span>
        <span v-else class="landing-title-cn-2">{{ t("mu.center_title_2") }}</span>
      </h2>
      <h1 class="center-banner-description">{{ t("mu.center_description") }}</h1>
      <img src="@/assets/MuLanding/mobile/Image-Future.png" alt="" class="center-banner-image" />
    </div>
</template>
  
<script setup>
  import { useI18n } from 'vue-i18n';
  
  const { t } = useI18n();
  const i18n = useI18n();
  
</script>