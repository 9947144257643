import axios from "axios";
import { BASE_URL } from "@/config";
import scTranslation from '@/locales/sc.json';
import tcTranslation from '@/locales/tc.json';
import enTranslation from '@/locales/en.json';

let storeTranslation = {};

const fallbackLocale = {
  sc: scTranslation,
  tc: tcTranslation,
  en: enTranslation,
};

const fetchTranslations = async () => {
  try {
    const res = await axios({
      method: "GET",
      url: `${BASE_URL || "/api/v1"}/mts/translation`,
      timeout: 3000,
    });

    if (res.status === 200) {
      storeTranslation = res.data;
    }
  } catch (error) {
    console.error('Error: Failed to get translation');
  }
};
const translationsReady = fetchTranslations();

const getFallbackTranslation = (locale) => fallbackLocale[locale] || fallbackLocale.sc;

export const getTranslation = async (locale) => {
  await translationsReady;
  return storeTranslation[locale] || getFallbackTranslation(locale);
};
