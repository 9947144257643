<template>
    <div class="tag">
        <h3>{{ t("about.global") }}</h3>
        <p>{{t('about.m-2019-g1')}}</p>
    </div>
    <div class="tag">
        <h3>{{ t("about.safety") }}</h3>
        <p>{{t('about.m-2019-s1')}}</p>
        <p>{{t('about.m-2019-s2')}}</p>
        <p>{{t('about.m-2019-s3')}}</p>
    </div>
    <!--div class="tag">
        <h3>{{ t("about.product") }}</h3>
        <p>{{t('about.m-2019-p1')}}</p>
        <p>{{t('about.m-2019-p2')}}</p>
        <p>{{t('about.m-2019-p3')}}</p>
        <p>{{t('about.m-2019-p4')}}</p>
    </div-->
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>
