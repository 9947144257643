<template>
    <section id="stats">
        <div class="container">
            <div class="users icon-wrapper ">
                <img class="users-icon icon"
                    src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Stats/Clients.svg" alt="users-icon">
                <p class="count-customers count">400,000+</p>
                <p class="desc customers" v-t="'stats.customers'"></p>
            </div>
            <div class="presence icon-wrapper">
                <img class="users-icon icon"
                    src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Stats/Location.svg" alt="users-icon">
                <p class="count-customers count">150+</p>
                <p class="desc customers" v-t="'stats.presence'"></p>
            </div>
            <div class="partner icon-wrapper">
                <img class="users-icon icon"
                    src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Stats/Partner.svg" alt="users-icon">
                <p class="count-customers count">250+</p>
                <p class="desc customers" v-t="'stats.partner'"></p>
            </div>
            <div class="office icon-wrapper">
                <img class="users-icon icon"
                    src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Stats/Office.svg" alt="users-icon">
                <p class="count-customers count">20+</p>
                <p class="desc customers" v-t="'stats.office'"></p>
            </div>

        </div>

    </section>
</template>

<script>
</script>


<style lang="less" scoped>
@import '../../less/mediaQuery.less';
#stats {
    margin: 160px 0;
    @media @mobile-screen {
        margin: 80px 0;
    }

    p {
        margin: 0;
    }

    .container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        @media @mobile-screen {
            flex-direction: column;
            gap: 48px;
        }

        @media only screen and (min-width:420px) and (max-width: 834px) {
            display: block;
        }

        .icon {
            width: 48px;
            height: 48px;
            margin-bottom:24px;
            @media @mobile-screen {
                margin-bottom:12px;
                width:32px;
                height:32px;
            }

        }

        .count {
            font-family: "Oswald" !important;
            font-size: 48px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 42px;
            letter-spacing: normal;
            text-align: center;
            color: #3e3a39;
            margin-bottom:16px;
            @media @mobile-screen {
                margin-bottom:8px;
                font-family: "Oswald" !important;
                font-size: 36px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.17;
                letter-spacing: normal;
                text-align: center;
                color: #3e3a39;
            }
        }

        .desc {
            
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #3e3a39;
        }

        .icon-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @media only screen and (min-width:420px) and (max-width: 834px) {
                display: inline-block;
                width: 47%;
                margin-bottom: 48px;
                vertical-align: top;
            }
        }
    }
}
</style>