<template>
    <div>
      <div v-if="showPlayer.mobile" style="display: flex; position: relative;">
        <video
          ref="mobileVideoRef"
          @playing="handlePlay"
          @pause="handlePause"
          @ended="handleVideoEnd"
          @click="pauseVideo"
          @touchend="pauseVideo"
          preload="auto"
          controls
          style="width: 100%; height: 100%;"
        >
          <source :src="mobileVideo" type="video/webm" />
          <source :src="mobileVideoMp4" type="video/mp4" />
          <template v-for="item in mobileSubtitle" :key="item.label">
            <track :src="item.src" :kind="item.kind" :src-lang="item.srcLang" :default="item.default" v-bind="item"/>
          </template>
        </video>
        <div v-if="pause" class="overlay-prevent-click">
          <Vue3Lottie
            data-aos="fade-up" 
            data-aos-duration="800"
            :animation-data="PlayButton" 
            :loop="true"
            class="play-button-video"
            @click="resumeVideo"
          />
        </div>
      </div>

      <div v-else-if="showPlayer.desktop" class="player-wrapper" :style="{ display: showPlayer.desktop ? 'block' : 'none', height: videoHeight + 'px' }">
        <video
          ref="desktopVideoRef"
          @playing="handlePlay"
          @pause="handlePause"
          @ended="handleVideoEnd"
          @click="pauseVideo"
          @touchend="pauseVideo"
          preload="auto"
          controls
          style="width: 100%; height: 100%;"
        >
          <source :src="desktopVideo" type="video/webm" />
          <source :src="desktopVideoMp4" type="video/mp4" />
          <template v-for="item in desktopSubtitle" :key="item.label">
            <track :src="item.src" :kind="item.kind" :src-lang="item.srcLang" :default="item.default" v-bind="item"/>
          </template>
        </video>
        <div v-if="pause" class="overlay-prevent-click">
          <Vue3Lottie 
            data-aos="fade-up" 
            data-aos-duration="800"
            :animation-data="PlayButton"
            :loop="true"
            class="play-button-video"
            @click="resumeVideo"
          />
        </div>
      </div>

      <div class="landing-banner" v-else>
        <h2 class="landing-title">
          <span v-if="locale === 'en'" class="landing-title-1">{{ t('mu.landing_title_1') }}</span>
          <span v-else class="landing-title-cn-1">{{ t('mu.landing_title_1') }}</span>
          <span v-if="locale === 'en'" class="landing-title-2">{{ t('mu.landing_title_2') }}</span>
          <span v-else class="landing-title-cn-2">{{ t('mu.landing_title_2') }}</span>
        </h2>
        <h1 class="landing-title-3">{{ t('mu.landing_title_3') }}</h1>
        <Vue3Lottie
          data-aos="fade-up" 
          data-aos-duration="800"
          :animation-data="PlayButton"
          :loop="true"
          class="play-button"
          @click="playVideo"
        />
      </div>
    </div>
</template>

<script setup>
  import { ref, onMounted, onUnmounted, watch } from 'vue';
  import { useI18n } from 'vue-i18n';
  import "../../less/style.css"
  import PlayButton from '@/assets/MuLanding/Icon_Play_White.json'
  import { Vue3Lottie } from 'vue3-lottie'
  import enSrtMobile from "@/assets/MuLanding/videoSrt/en/MU_Landing_Page_EN_4x5.vtt";
  import enSrtDesktop from "@/assets/MuLanding/videoSrt/en/MU_Landing_Page_EN_16x9.vtt";
  import scSrtMobile from "@/assets/MuLanding/videoSrt/sc/MU_Landing_Page_SC_4x5.vtt";
  import scSrtDesktop from "@/assets/MuLanding/videoSrt/sc/MU_Landing_Page_SC_16x9.vtt";
  import tcSrtMobile from "@/assets/MuLanding/videoSrt/tc/MU_Landing_Page_TC_4x5.vtt";
  import tcSrtDesktop from "@/assets/MuLanding/videoSrt/tc/MU_Landing_Page_TC_16x9.vtt";
  import VideoPlayer from "./videoPlayer";
  import {checkBrowser} from "@/js/checkBrowser";

  const { isMobile, browser, isAppleChrome } = checkBrowser();

  
  const { t } = useI18n();
  const { locale } = useI18n();
  const mobileVideo = "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/mufc/video/DOO-Group_Launch_4x5_Clean2.webm";
  const mobileVideoMp4 = "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/mufc/video/DOO-Group_Launch_4x5_Clean.mp4";
  const desktopVideo = "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/mufc/video/DOO-Group_Launch_16x9_Clean.webm";
  const desktopVideoMp4 = "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/mufc/video/DOO-Group_Launch_16x9_Clean.mp4";
  
  const mobileVideoRef = ref(null);
  const desktopVideoRef = ref(null);
  const desktopVideoJSRef = ref(null);
  const videoHeight = ref(0);
  const pause = ref(false);
  const showPlayer = ref({ desktop: false, mobile: false });
  
  const mobileSubtitle = [
    {
      label: "English",
      kind: "captions",
      srcLang: "en",
      default: locale.value === "en",
      src: enSrtMobile,
    },
    {
      label: "繁体中文",
      kind: "captions",
      srcLang: "tc",
      default: locale.value === "tc",
      src: tcSrtMobile,
    },
    {
      label: "简体中文",
      kind: "captions",
      srcLang: "sc",
      default: locale.value === "sc",
      src: scSrtMobile,
    },
  ];
  const desktopSubtitle = [
    {
      label: "English",
      kind: "captions",
      srcLang: "en",
      srclang: "en",
      default: locale.value === "en",
      src: enSrtDesktop,
    },
    {
      label: "繁体中文",
      kind: "captions",
      srcLang: "tc",
      srclang: "tc",
      default: locale.value === "tc",
      src: tcSrtDesktop,
    },
    {
      label: "简体中文",
      kind: "captions",
      srcLang: "sc",
      srclang: "sc",
      default: locale.value === "sc",
      src: scSrtDesktop,
    },
  ];
  
  const handlePlay = () => {
    pause.value = false;
  };
  
  const handlePause = () => {
    pause.value = true;
  };
  
  const handleVideoEnd = () => {
    showPlayer.value = { desktop: false, mobile: false };
  };
  
  const pauseVideo = (event) => {
    event.target.pause = true;
  };
  
  const resumeVideo = () => {
    pause.value = false;
    if (mobileVideoRef.value) mobileVideoRef.value.play();
    if (desktopVideoRef.value) desktopVideoRef.value.play();
  };
  
  const playVideo = () => {
    pause.value = false;
    if (window.innerWidth < 600) {
      showPlayer.value = { desktop: false, mobile: true };
      if (mobileVideoRef.value) mobileVideoRef.value.play();
    } else {
      showPlayer.value = { desktop: true, mobile: false };
      if (desktopVideoRef.value) desktopVideoRef.value.play();
    }
  };
  
  const calHeight = () => {
    videoHeight.value = (window.innerWidth * 9) / 16;
  };

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    onReady: (player) => {
      desktopVideoJSRef.current = player;
      player.on("pause", () => {
        setPause(true);
      });
      player.on("play", () => {
        setPause(false);
      });
      player.on("ended", () => {
        setShowPlayer({ desktop: false, mobile: false });
      });
    },
    tracks: desktopSubtitle,
    sources: [
      {
        src: desktopVideo,
        type: "video/mp4",
      },
    ],
  };
  
  const changeSubtitleLanguage = (track) => {
    for (let index = 0; index < track.length; index++) {
      if (track[index].language === locale.value) track[index].mode = "showing";
      else track[index].mode = "disabled";
    }
  };
  
  onMounted(() => {
    calHeight();
    window.addEventListener("resize", calHeight);
    watch(locale, (newVal, oldVal) => {
      if (desktopVideoRef.value) changeSubtitleLanguage(desktopVideoRef.value.textTracks);
      if (mobileVideoRef.value) changeSubtitleLanguage(mobileVideoRef.value.textTracks);
      if (desktopVideoJSRef.value) changeSubtitleLanguage(desktopVideoJSRef.value.textTracks());
  }, { deep: true });
  });
  
  onUnmounted(() => {
    window.removeEventListener("resize", calHeight);
  });
  
</script>