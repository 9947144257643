<template>
    <div class="tag">
        <h3>{{ t("about.brand") }}</h3>
        <p>{{t('about.m-2020-b1')}}</p>
        <img :src="`${imageUrl2}logo-sub-brand-pe-colour-light-bg.png`" alt="PeterElish" class="peterElish"/>
        <p>{{t('about.m-2020-b2')}}</p>
        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/about/new-milestone/sub-brand_FP.png" alt="Doo Financial" class="doofinancial"/>
        <p>{{t('about.m-2020-b3')}}</p>
    </div>
    <div class="tag">
        <h3>{{ t("about.global") }}</h3>
        <p>{{t('about.m-2020-g1')}}</p>
        <p>{{t('about.m-2020-g2')}}</p>
    </div>
    <div class="tag">
        <h3>{{ t("about.safety") }}</h3>
        <p>{{t('about.m-2020-s1')}}</p>
        <!-- <p>{{t('about.m-2020-s2')}}</p> -->
        <p>{{t('about.m-2020-s3')}}</p>
    </div>
    <!--div class="tag">
        <h3>{{ t("about.product") }}</h3>
        <p>{{t('about.m-2020-p1')}}</p>
        <p>{{t('about.m-2020-p2')}}</p>
        <p>{{t('about.m-2020-p3')}}</p>
        <p>{{t('about.m-2020-p4')}}</p>
        <p>{{t('about.m-2020-p5')}}</p>
    </div-->
</template>

<script setup>
import { useI18n } from 'vue-i18n';
const imageUrl2 = "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/about/";

const { t } = useI18n();
</script>