<template>
    <div id="main-modal"
        :class="commonStore.modal.isOpen ? 'show ' + commonStore.modal.name : commonStore.modal.name">
        <div class="modal-overlay" @click="(status === false) ? commonStore.closeModal() : refreshPage()"></div>
        <div class="modal-animation">
            <div class="modal-content-wrapper">
                <MUArticles v-if="commonStore.modal.name === 'MUArticles'" />
                <ContactForm v-if="commonStore.modal.name === 'ContactForm'" />
            </div>
            
            <div class="modal-btn-close" @click="(status === false) ? commonStore.closeModal() : refreshPage()">
                <img :src="imageUrl + 'icon_close.svg'" />
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useCommonStore } from '@/stores/common'
    import MUArticles from './MUArticles.vue'
    import ContactForm from './ContactForm.vue'

    const commonStore = useCommonStore()

    const imageUrl = "http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/8-year-anniversary/8-year-ib/"
    const status = false
</script>

<style lang="less">
#main-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 0 16px;
    pointer-events: none;

    &.show {
        pointer-events: all;

        .modal-overlay {
            transition: 1s;
            opacity: 0.6;
        }

        .modal-animation {
            transition: transform 1s, opacity 1s;
            transform: translate(-50%, calc(-50% - 19px));
            opacity: 1;
        }
    }
}

.modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0;
    transition: 1s;
}

.MUArticles {

    .modal-content-wrapper::-webkit-scrollbar {
    width: 5px;
    }

    .modal-content-wrapper::-webkit-scrollbar-track {
    background: #e3e3e3;    
    }

    .modal-content-wrapper::-webkit-scrollbar-thumb {
    background-color: #e03323; 
    border-radius: 20px;
    }
}

.modal-animation {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
    width: calc(100% - 32px);
    max-width: 831px;
    opacity: 0;
    transition: transform 1s, opacity 1s;

    @include mobile-screen () {
        transform: translate(-50%, -300px);
        top: 50%;
    }

    .modal-btn-close {
        position: relative;
        bottom: -18px;
        left: 0;
        margin-left: -15px;
        font-size: 14px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ccc;
        cursor: pointer;
        height:0;
        img {
            width:24px;
        }
    }
}

.modal-content-wrapper {
    padding: 16px;
    background-color: #ffffff;
    /* border-radius: 10px; */
    box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.15);
    border-radius: 0;
    height: auto;
    max-height: 78vh;
    overflow-y: auto;

    .about-title {
        margin-bottom: 22px;
    }

    .about-description {
        font-size: 20px;
        font-weight: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #313131;
        margin-bottom: 12px;

        @include mobile-screen () {
            font-size: 12px;
        }
    }
}

.ContactForm {
    .modal-content-wrapper {
        max-height: 92vh;
    }
}
</style>