<template>
    <section id="footer">
        <FooterBanner></FooterBanner>
        <FooterTop :info="footerInfo?.footer_menu"></FooterTop>
        <FooterBody :info="footerInfo?.compliance_menu"></FooterBody>
        <FooterBottom :copyright="footerInfo?.compliance_menu?.copyright?.[1]"></FooterBottom>
    </section>
</template>

<script scoped>
import { useI18n } from 'vue-i18n';
import FooterBanner from './FooterModels/FooterBanner.vue'
import FooterTop from './FooterModels/FooterTop.vue'
import FooterBody from './FooterModels/FooterBody.vue'
import FooterBottom from './FooterModels/FooterBottom.vue'
import { getFooter } from '@/api/publicCms'

    export default {
        data() {
            return {
                footerInfo: {}
            }
        },
        components: {
            FooterBanner,
            FooterTop,
            FooterBody,
            FooterBottom,
        },
        methods: {
            async handleGetFooter() {
                const data = await getFooter(this.$i18n.locale)
                if (data) {
                    this.footerInfo = data?.data
                }
            }
        },
        async mounted() {
            await this.handleGetFooter()
        }
    }

</script>

<style lang="less" scoped >
@import '../../less/mediaQuery.less';
#footer{
    margin-top: 160px;
    @media @mobile-screen {
        margin-top: 80px;
    }
}</style>