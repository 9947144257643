<template>
    <div class="clock">
        <div class="hour" ref="hourRef"></div>
        <div class="min" ref="minRef"></div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
const props = defineProps(['timeZoneOffSet'])
const deg = 6;
const hourRef = ref()
const minRef = ref()
let day

const setClock = () => {
    const date = new Date(); // Get the current date and time
    const n = date.getTimezoneOffset(); // Get the timezone offset in minutes
    const gtmDate = new Date(date.getTime() + ((n + (props.timeZoneOffSet * 60)) * 60 * 1000)); // create new date object with GMT+5 time

    updateClock(gtmDate.getHours(), gtmDate.getMinutes())
    day = gtmDate.getTime()
};


const updateClock = (hour, min) => {
    const hh = hour * 30;
    const mm = min * deg;
    if (hourRef.value && minRef.value) {
        hourRef.value.style.transform = `rotateZ(${hh + mm / 12}deg)`;
        minRef.value.style.transform = `rotateZ(${mm}deg)`;
    }
}
onMounted(() => {
    setClock();
    setInterval(() => {
        const date = new Date(day += 60000)
        updateClock(date.getHours(), date.getMinutes())
    }, 60000);
})
</script>
<style>
.clock {
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    background-image: url("https://imvpn22.github.io/analog-clock/clock.png");
    background-position: center center;
    background-size: cover;
    border-radius: 50%;
    border: 4px solid white;
    transition: all ease 0.2s;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.clock:before {
    content: "";
    height: 7.3px;
    width: 7.3px;
    background-color: #3e3a39;
    position: absolute;
    border-radius: 50%;
    z-index: 1000;
    transition: all ease 0.2s;
}

.hour,
.min,
.sec {
    position: absolute;
    display: flex;
    justify-content: center;
    border-radius: 50%;
}

.hour {
    height: 87.2px;
    width: 10em;
}

.hour:before {
    content: "";
    position: absolute;
    height: 50%;
    width: 1.8px;
    background-color: #e03323;
    border-radius: 6px;
}

.min {
    height: 145.4px;
    width: 12em;
}

.min:before {
    content: "";
    height: 50%;
    width: 2.8px;
    background-color: #3e3a39;
    border-radius: 4px;
}

.sec {
    height: 13em;
    width: 13em;
}

.sec:before {
    content: "";
    height: 60%;
    width: 2px;
    background-color: #f00;
    border-radius: 2px;
}
</style>