<template>
   <Teleport to="head">
      <title>{{$t("seo_title")}} | {{$t("seo_title2")}}</title>
      <meta name="description" :content="$t('seo_desc')"/>
      <meta name="keyword" :content="$t('seo_keyword')"/>
  </Teleport>
  <div class="milestone header-gap">
    <div class="sub-banner">
      <div class="container">
        <h1 v-html="$t('about.milestone-title')"></h1>
        <p v-html="$t('about.milestone-sub-title')"></p>
      </div>
    </div>
    <div class="development-journey">
      <div class="container">
        <h2 v-html="$t('about.developmentJourney')"></h2>
        <p v-html="$t('about.developmentJourneyDesc')"></p>
        <div class="carousel">
          <Journey />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import Journey from './Journey';
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Journey
  },
  
  
};
</script>

<style lang="less">
@import '../less/mediaQuery.less';
.milestone {
  .sub-banner {
    background:url('https://cdn.dootech.io/doo/about/milestone/milestone.png') no-repeat center top;
    @media @mobile-screen {
      background:url('https://cdn.dootech.io/doo/about/milestone/milestone_mobile.png') no-repeat center top;
    }
    .container {
      @media only screen and (max-width:1500px) and (min-width:835px) {
        max-width: 40%;
        margin: 0;
      }
    }
  }
  img {
    max-width:100%;
  }
  .development-journey {
    h2{
      font-size: 48px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #3e3a39;
      margin-bottom:24px;
      @media @mobile-screen {
        font-size: 28px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #3e3a39;
        margin-bottom:16px;
      }
    }
    p{
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #3e3a39;
      max-width: 1329px;
      margin:0 auto 80px;
      @media @mobile-screen {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #3e3a39;
        margin-bottom:40px;
      }
    }
  }
  .carousel__prev, .carousel__next {
    top:initial;
    bottom:80px;
    width: fit-content;

    @media only screen and (min-width:835px) {
      display:none;
    }

    span {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #e03323;
    }
  }
  .carousel {
    text-align: left;
    max-width: 1329px;
    margin: auto;
    section.carousel {
      padding-top:90px;
      padding-bottom:160px;
      @media @mobile-screen {
        padding-top:73px;
        padding-bottom:144px;
      }
    }
    .carousel__viewport {
      @media only screen and (min-width:835px) {
        &::-webkit-scrollbar {
          display: none; /* for Chrome, Safari, and Opera */
        }
      }
    }
    .carousel__slide {
      justify-content: space-between;
      align-items: flex-start;
      padding: 0 2px;
      height: fit-content;
      @media @mobile-screen {
        padding:0;
        display:block;
      }
      > img {
        @media only screen and (min-width:835px) {
          max-width: min(650px, 50%);
        }
        @media @mobile-screen {
          margin-top:40px;
        }
      }
    }
    .content {
      max-width:521px;
      width:100%;
      @media only screen and (min-width:835px) {
        width: 50%;
      }
      .tag {
        margin-bottom:64px;
        &:last-child {
          margin-bottom:0px;
        }
      }
      h3 {
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #3e3a39;
        margin-bottom:24px;
        @media @mobile-screen {
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: normal;
          color: #3e3a39;
        }
      }
      p {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #3e3a39;
        position:relative;
        padding-left:28px;
        text-align: left;
        margin-bottom:16px;
        &:last-child {
          margin-bottom:0;
        }
        &:before {
          content:'';
          width: 12px;
          height: 0px;
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 9px solid #e03323;
          position: absolute;
          left: 3px;
          top: 8px;

          @media @mobile-screen {
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            border-left: 6px solid #e03323;
          }
        }
      }
      img {
        margin-bottom:24px;
        padding-left: 28px;
        &:last-child {
          margin-bottom:0;
        }
      }
      .doogroup {
        max-width:204px;
        @media @mobile-screen {
          max-width:186px;
        }
      }
      .dooclearing {
        max-width:170px;
        @media @mobile-screen {
          max-width:155px;
        }
      }
      .dooexchange {
        max-width:183px;
        @media @mobile-screen {
          max-width:153px;
        }
      }
      .dooprime, .exchange {
        max-width:168px;
        @media @mobile-screen {
          max-width:152px;
        }
      }
      .peterElish {
        max-width:192px;
        @media @mobile-screen {
          max-width:160px;
        }
      }
      .doofinancial {
        max-width:175px;
        @media @mobile-screen {
          max-width:160px;
        }
      }
      .doowealth {
        max-width:212px;
        @media @mobile-screen {
          max-width:188px;
        }
      }
      .lesspay {
        max-width:175px;
        @media @mobile-screen {
          max-width:160px;
        }
      }
    }
  }
  .carousel__pagination {
    justify-content: space-around;
    // border-bottom:1px solid rgba(158, 158, 159, 0.4);
    padding-bottom:20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    margin-top:0;
    @media @mobile-screen {
      padding-bottom: 16px;
      justify-content: space-between;
    }
    &::-webkit-scrollbar {
      width: 3px;
      height:3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 20px;
      scrollbar-width: thin;
    }
    .carousel__pagination-item {
      // @media @mobile-screen {
      //     &:first-child {
      //       .carousel__pagination-button {
      //         // padding-left:0;
      //         &:before {
      //           margin-left:-20px !important;
      //         }
      //       }
      //     }
      //     &:last-child {
      //       .carousel__pagination-button {
      //         // padding-right:0;
      //         &:before {
      //           margin-left:-3px !important;
      //         }
      //       }
      //     }
      // }
      .carousel__pagination-button {
        width: 72px;
        height:23px;
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: #9e9e9f;

        @media @mobile-screen {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #9e9e9f;
          width: auto;
          padding: 0 18px;
        }
        &:after {
          background-color: transparent;
          font-size: 20px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          color: #9e9e9f;
          height: 100%;
          width:100%;
          vertical-align: bottom;

          @media @mobile-screen {
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: #9e9e9f;
          }
        }
        &.carousel__pagination-button--active {
          position:relative;
          &:after {
            font-size: 28px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.8;
            letter-spacing: normal;
            color: #e03323;
            top:initial;
            bottom:0;

            @media @mobile-screen {
              font-size: 16px;
              font-weight: bold;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.5;
              letter-spacing: normal;
              text-align: center;
              color: #e03323;
            }
          }
          &:before {
            content:'';
            background-color: #e03323;
            width: 100%;
            height: 1px;
            border: 1px solid #e03323;
            position:absolute;
            left: 50%;
            bottom: -87%;
            transform: translate(-50%, 0%);
            @media @mobile-screen {
              bottom: -70%;
            }
          }
        }
      }
      &:first-child .carousel__pagination-button:after {
        content:'2014';
      }
      &:nth-child(2) .carousel__pagination-button:after {
        content:'2017';
      }
      &:nth-child(3) .carousel__pagination-button:after {
        content:'2018';
      }
      &:nth-child(4) .carousel__pagination-button:after {
        content:'2019';
      }
      &:nth-child(5) .carousel__pagination-button:after {
        content:'2020';
      }
      &:nth-child(6) .carousel__pagination-button:after {
        content:'2021';
      }
      &:nth-child(7) .carousel__pagination-button:after {
        content:'2022';
      }
      &:nth-child(8) .carousel__pagination-button:after {
        content:'2023';
      }
      &:nth-child(9) .carousel__pagination-button:after {
        content:'2024';
      }
    }
  }
  
}


</style>