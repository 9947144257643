<template>
    <div class="modal-content ContactForm">
        <div class="form-wrapper">
            <FormKit type="form" :value="initialState" @submit="handleSubmit" id="contactUsForm" :actions="false"
                #default="{ state: { valid, submitted }, disabled, loading }"
                incomplete-message="Please fill up all the fields.">
                <div class="form">
                    <p class="title" v-t="'contact_us.form_title'"></p>
                    <FormKit type="text" name="lastName" :label="t('contact_us.last_name')"
                        validation="required:contact_us.last_name" maxlength="225"
                        :validation-messages="{ required: requiredMessage }" />
                    <FormKit type="text" name="firstName" :label="t('contact_us.first_name')"
                        validation="required:contact_us.first_name" maxlength="225"
                        :validation-messages="{ required: requiredMessage }" />
                    <FormKit type="text" name="tel" outer-class="phone" :label="t('contact_us.tel')"
                        validation="required:contact_us.tel|number" maxlength="225"
                        :validation-messages="{ required: requiredMessage, number: numberMessage }" inputmode="tel" />
                    <FormKit type="text" name="email" outer-class="email" :label="t('contact_us.email')"
                        validation="required:contact_us.email|email" maxlength="225"
                        :validation-messages="{ required: requiredMessage, email: emailMessage }" inputmode="email" />

                    <FormKit type="text" name="companyName" :label="t('contact_us.company_name')"
                        outer-class="companyName" maxlength="225" />

                        <FormKit type="checkbox" :label="t('contact_us.query')" name="inquiryType"
                            outer-class="enquiryType" @input="handleCheckbox"
                            :options="[
                                {value: t('contact_us.service_consultation'), label: t('contact_us.service_consultation'), attrs:{ disabled: checkboxValidation(t('contact_us.service_consultation'))}},
                                {value: t('contact_us.business_cooperation'), label: t('contact_us.business_cooperation'), attrs:{ disabled: checkboxValidation(t('contact_us.business_cooperation'))}},
                                {value: t('contact_us.media_interview'), label: t('contact_us.media_interview'), attrs:{ disabled: checkboxValidation(t('contact_us.media_interview'))}}
                                ]" 
                            validation="+max:1|+min:1" :validation-messages="{ min: minSelectionMessage }"/>

                    <FormKit type="checkbox" :label="t('contact_us.brand')" name="interestedBranding" outer-class="brandType"
                        :options="['Doo Group', 'Doo Clearing', 'Doo Financial', 'Doo Wealth', 'Doo Property', 'Doo Payment',
                        'FinPoints', 'Doo Academy', 'Doo Health', 'Doo Consulting', 'Doo Cloud', 'Doo Digital Plus']"
                        validation="+min:1" :validation-messages="{ min: minSelectionMessage }" />
                        
                    <div class="col-span-2">
                        <FormKit type="textarea" name="message" :label="t('contact_us.message')"
                            input-class="min-height-88" validation="required:contact_us.message" maxlength="225"
                            :validation-messages="{ required: requiredMessage }" />
                    </div>
                    <div class="col-span-2 h-captcha" v-if="hCaptachaKey">
                        <VueHcaptcha ref="hCaptchaComponent" :sitekey="hCaptachaKey" @verify="onVerify"
                            @expired="onExpire" @challenge-expired="onChallengeExpire" @error="onError"
                            @reset="onReset" />
                    </div>
                    <div class="col-span-2 button-container">
                        <FormKit type="submit" :label="t('contact_us.submit')"
                            :disabled="!(valid && hCaptcha.verified) || disabled" wrapper-class="button-wrapper">
                        </FormKit>
                        <p class="submit-result" :class="submitStatus ? 'green' : 'red'" v-if="formSubmitComplete">
                            {{ submitStatus ? t('contact_us.submit_success') : t('contact_us.submit_failed') }}
                        </p>
                    </div>
                </div>
            </FormKit>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import '@formkit/themes/genesis'
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha'
import { getHCaptachaKey, sendEmail } from '@/api/publicCms'
import { reset } from '@formkit/core'

import { useI18n } from 'vue-i18n';

const { t } = useI18n()
const submitStatus = ref(true)
const formSubmitComplete = ref(false)
const selectedOption = ref(null);

const initialState = {
    company: "",
    email: "",
    firstName: "",
    inquiryType: [],
    interestedBranding: [],
    lastName: "",
    message: "",
    tel: ""
};

const hCaptachaKey = ref()
const hCaptchaComponent = ref()
const hCaptcha = ref({
    verified: false,
    expired: false,
    token: '',
    eKey: '',
    error: '',
})
const onReset = () => {
    hCaptcha.value = {
        verified: false,
        expired: false,
        token: '',
        eKey: '',
        error: '',
    }
}
const onVerify = (tokenStr, ekey) => {
    hCaptcha.value = {
        ...hCaptcha.value,
        verified: true,
        token: tokenStr,
        eKey: ekey,
    }
}
const onExpire = () => {
    hCaptcha.value = {
        ...hCaptcha.value,
        verified: false,
        token: null,
        eKey: null,
        expired: true,
    }
    console.log('Expired')
}

const onChallengeExpire = () => {
    hCaptcha.value = {
        ...hCaptcha.value,
        verified: false,
        token: null,
        eKey: null,
        expired: true,
    }
    console.log('Challenge expired')
}
const onError = (err) => {
    hCaptcha.value = {
        ...hCaptcha.value,
        token: null,
        eKey: null,
        error: err,
    }
    console.log(`Error: ${err}`)
}

const handleSubmit = async (data, node) => {
    formSubmitComplete.value = false
    data.company === undefined || data.company === '' && delete data.company;
    const res = await sendEmail(hCaptcha.value.token, {...data, inquiryType: String(data.inquiryType)});
    if (res) {
        node.reset();
        submitStatus.value = true
    }
    else
        submitStatus.value = false
    hCaptchaComponent.value.reset()
    formSubmitComplete.value = true

}

const handleGetHCaptachaKey = async () => {
    const data = await getHCaptachaKey()
    if (data)
        hCaptachaKey.value = data?.hcaptachaKey
}

const requiredMessage = ({ name, args, node }) => t('contact_us.required', { title: t(`${args[0]}`) })
const numberMessage = () => t('contact_us.number_only', { title: t('contact_us.tel') })
const emailMessage = () => t('contact_us.email_notValid', { title: t('contact_us.email') })
const minSelectionMessage = () => t('contact_us.checkbox_min');

const handleCheckbox = (event) => {
  if (Object.keys(event) && Object.keys(event).length) {
    selectedOption.value = event[0];
  } 
  else {
    selectedOption.value = null;
  }
};

const checkboxValidation = (option) => {
  return selectedOption.value !== option && selectedOption.value !== null;
}

onMounted(() => {
    handleGetHCaptachaKey()
})

</script>

<style lang="less">
@import '../../less/mediaQuery.less';

.ContactForm {
    .modal-content-wrapper {
        padding: 24px;
    }

    .form {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column: span 2 / span 2;
        column-gap: 24px;

        .title {
            text-align: left;
            grid-column: span 2 / span 2;
            font-size: 28px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            color: #3e3a39;
            margin-bottom: 24px;


            @media @mobile-screen {
                font-size: 20px;
                padding-top: 0px;
            }
        }
    }

    .min-height-88 {
        min-height: 88px !important;
    }

    .button-wrapper {
        width: 100%;
    }

    .submit-result {
        padding: 8px 16px;
        margin-top: 24px;
        border: 2px solid;
        text-align: left
    }

    .green {
        border-color: #60b253;
    }

    .red {
        border-color: #e03323;
    }

    button.formkit-input {
        justify-self: left;
        width: 150px;
        margin-right: auto;
        padding: 12px 36px;
        background-color: #e03323;
        border: none;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #fff;
        border-radius: 0%;
        width: 100%;

        @media @mobile-screen {
            grid-column: span 2 / span 2;
        }
    }
}
</style>