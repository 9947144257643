<template>
    <div class="contact-us">
        <h2>{{$t("business-overview.contact-us")}}</h2>
        <p>{{$t( boc.bocontent + ".contact-us-desc")}}</p>
        <a @click="openModalContactForm">{{$t("business-overview.contact-us-now")}}</a>
    </div>
</template>

<script setup>
    import { useCommonStore } from '@/stores/common'
    import { defineProps } from 'vue';

    const commonStore = useCommonStore()

    const openModalContactForm = () => {
        commonStore.openModal('ContactForm')
    }

    const boc = defineProps(["bocontent"])

</script>

<style lang="less">
@import '../less/mediaQuery.less';

.bo {
  .contact-us {
    padding:160px 15px;
    margin-top:160px;
    background:#f8f8f8 url('https://cdn.dootech.io/doo/business_overview/contactUs_bg.png') no-repeat center top;
    max-width: 2560px;
    margin: 160px auto 0;
    @media only screen and (max-width:950px) {
      margin-top:80px;
      padding:80px 15px;
      background:#f8f8f8 url('https://cdn.dootech.io/doo/business_overview/contactUs_bg-mobile.png') no-repeat center top;
      background-size: cover;
    }
  
    h2 {
      font-size: 48px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #3e3a39;
      margin-bottom:24px;
  
      @media @mobile-screen {
        font-size: 28px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #3e3a39;
        margin-bottom:16px;
      }
    }
    p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #3e3a39;
      margin-bottom:24px;
  
      @media @mobile-screen {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #3e3a39;
        margin-bottom:32px;
      }
    }
    a {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      padding:12px 16px;
      background-color: #e03323;
      color:#fff;
      display:block;
      display: inline-block;
      width: fit-content;
      min-width: 150px;
    }
  }
}
</style>