<template>
    <div class="image-with-text-box-container">
      <img src="@/assets/MuLanding/Image-Intro.png" alt="winning" data-aos="fade-left" />
      <div class="content" data-aos="fade-right">
        <img src="@/assets/MuLanding/icon-arrow-right-white.webp" alt="arrow right" />
        <div>
          <div class="image-with-text-box-title">
            <span style="font-weight: bold;">{{ t("mu.image_with_text_box_title") }}</span>
          </div>
          <div class="image-with-text-box-description">{{ t("mu.image_with_text_box_description") }}</div>
        </div>
      </div>
    </div>
    <div class="image-with-text-box-container-mobile" :style="{ paddingBottom: paddingBottom + 'px' }">
      <div>
        <img src="@/assets/MuLanding/mobile/Image-Intro.png" alt="" />
        <div class="red-content" ref="contentRef">
          <div class="title">{{ t("mu.image_with_text_box_title") }}</div>
          <div class="description">{{ t("mu.image_with_text_box_description") }}</div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, onUnmounted } from 'vue';
  import { useI18n } from 'vue-i18n';
  
  const { t } = useI18n();
  const paddingBottom = ref(0);
  const contentRef = ref(null);
  
  const handleWindowChangeSize = () => {
    if (window.innerWidth <= 600 && contentRef.value) {
      paddingBottom.value = contentRef.value.clientHeight + 30;
    }
  };
  
  onMounted(() => {
    handleWindowChangeSize();
    window.addEventListener("resize", handleWindowChangeSize);
  });
  
  onUnmounted(() => {
    window.removeEventListener("resize", handleWindowChangeSize);
  });
  </script>