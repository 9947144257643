<template>
  <carousel :items-to-show="1" :autoplay="20000" :wrap-around="true" v-model="currentIndex">
    <slide v-for="(item, index) in yearsList" :key="index" :class="`slide-${index}`">
      <div class="content">
        <component v-if="item?.components" :is="item?.components" />
        <div v-else v-html="item.content">
        </div>
      </div>
      <img :class="`${item.imageMobile ? 'web' : ''}`" :src="`${item.image}`" />
      <img :class="`${item.imageMobile ? 'mobile' : 'hide'}`" :src="`${item.imageMobile}`" />
    </slide>
    <template #addons>
      <pagination>
        <template #pagination>
          <span v-for="(item, index) in yearsList" :key="index">
            {{ item.year }}
          </span>
        </template>
      </pagination>
      
      <navigation>
        <template #prev>
          <span> &lt; {{ yearsList[currentIndex].year == 2014 ? yearsList[yearsList.length - 1].year : (
            yearsList[currentIndex].year == 2017 ? "2014" : yearsList[currentIndex].year - 1 ) }} </span>
        </template>
        <template #next>
          <span> {{ yearsList[currentIndex].year == 2023 ? yearsList[0].year : ( yearsList[currentIndex].year == 2014 ? "2017" : yearsList[currentIndex].year + 1) }} >
          </span>
        </template>
      </navigation>
    </template>
  </carousel>
</template>
<script>
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { useI18n } from 'vue-i18n';
import { ref, onMounted, onUpdated } from 'vue';
import Year2014 from '@/components/Journey/Year2014.vue'
import Year2015 from '@/components/Journey/Year2015.vue'
import Year2016 from '@/components/Journey/Year2016.vue'
import Year2017 from '@/components/Journey/Year2017.vue'
import Year2018 from '@/components/Journey/Year2018.vue'
import Year2019 from '@/components/Journey/Year2019.vue'
import Year2020 from '@/components/Journey/Year2020.vue'
import Year2021 from '@/components/Journey/Year2021.vue'
import Year2022 from '@/components/Journey/Year2022.vue'
import Year2023 from '@/components/Journey/Year2023.vue'
import Year2024 from '@/components/Journey/Year2024.vue'
export default {
  setup() {
    const { t } = useI18n();
    const imageUrl = "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/about/milestone/";
    const yearsList = [
      {
        year: 2014,
        components: Year2014,
        image: imageUrl + "2014@1x.png"
      },
      // {
      //   year: 2015,
      //   components: Year2015,
      //   image: imageUrl + "2015@1x.png"
      // },
      // {
      //   year: 2016,
      //   components: Year2016,
      //   image: imageUrl + "2016@1x.png"
      // },
      {
        year: 2017,
        components: Year2017,
        image: imageUrl + "2017@1x.png"
      },
      {
        year: 2018,
        components: Year2018,
        image: imageUrl + "2018@1x.png"
      },
      {
        year: 2019,
        components: Year2019,
        image: imageUrl + "2019@1x.png",
        imageMobile: imageUrl + "2019@1x_mobile.png",
      },
      {
        year: 2020,
        components: Year2020,
        image: imageUrl + "2020@1x.png",
        imageMobile: imageUrl + "2020@1x_mobile.png",
      },
      {
        year: 2021,
        components: Year2021,
        image: imageUrl + "2021@1x.png",
        imageMobile: imageUrl + "2021@1x_mobile.png",
      },
      {
        year: 2022,
        components: Year2022,
        image: imageUrl + "2022@1x.png",
        imageMobile: imageUrl + "2022@1x_mobile.png",
      },
      {
        year: 2023,
        components: Year2023,
        image: imageUrl + "2023@1x.png"
      },
      {
        year: 2024,
        components: Year2024,
        image: imageUrl + "2024@1x.png",
        imageMobile: imageUrl + "2024@1x_mobile.png",
      },
    ];
    const prevYear = ref(2014);
    const nextYear = ref(2015);
    let currentIndex = ref(0);

    onMounted(() => {
      calculateSlideHeight();
    });

    onUpdated(() => {
      calculateSlideHeight();
    });

    function calculateSlideHeight() {
      
        let currentSlide = document.querySelector(`.slide-${currentIndex.value}`);
        if (currentSlide) {
          let slideHeight = currentSlide.offsetHeight;
          let viewport = document.querySelector(".carousel__viewport");
          viewport.style.maxHeight = slideHeight + "px";
        }
    }



    window.addEventListener("load", (event) => {
      calculateSlideHeight();

      const carousel__prev = document.querySelector(".carousel__prev");
      const carousel__next = document.querySelector(".carousel__next");
      const carousel__pagination = document.querySelector(".carousel__pagination");
      
      [carousel__prev, carousel__next].forEach((item) => {
        item.addEventListener("click",() => {
          let subBanner = document.querySelector('.sub-banner');
          let dh2 = document.querySelector('.development-journey .container > h2');
          let dp = document.querySelector('.development-journey .container > p');
          
          let totalHeight = 120 + subBanner.offsetHeight  + dh2.offsetHeight  + dp.offsetHeight ;
          window.scroll({
            top: totalHeight,
            behavior: 'smooth'
          });
        })
      })

    })

    return {
      yearsList,
      prevYear,
      nextYear,
      calculateSlideHeight,
      currentIndex
    }
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Year2014
  }, 
}
</script>