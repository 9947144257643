<template>
    <section id="future">
        <div class="header">
            <p v-t="'better-future.title'" class="title"></p>
            <p v-t="'better-future.sub-header'" class="sub-header"></p>
        </div>

        <nav class="future-nav">
            <button class="btn brokerage" @click="component = 'Brokerage'"><p :class="setActiveBrokerage" v-t="'better-future.title-brokerage'"></p></button>
            <button class="btn wealth" @click="component = 'Wealth'"><p :class="setActiveWealth" v-t="'better-future.title-wealth'"></p></button>
            <button class="btn property" @click="component = 'Property'"><p :class="setActiveProperty" v-t="'better-future.title-property'"></p></button>
            <button class="btn payment" @click="component = 'Payment'"><p :class="setActivePayment" v-t="'better-future.title-payment'"></p></button>
            <button class="btn finance" @click="component = 'Finance'"><p :class="setActiveFinance" v-t="'better-future.title-finance'"></p></button>
            <template v-if="isMobile">
                <button class="btn financial-education" @click="component = 'FinancialEducation'"><p :class="setActiveFinancialEducation" v-t="'better-future.title-financial-education'"></p></button>
                <button class="btn health-care" @click="component = 'HealthCare'"><p :class="setActiveHealthCare"  v-t="'better-future.title-health-care'"></p></button>
                <button class="btn consulting" @click="component = 'Consult'"><p :class="setActiveConsult" v-t="'better-future.title-consulting'"></p></button>
                <button class="btn cloud" @click="component = 'Cloud'"><p :class="setActiveCloud" v-t="'better-future.title-cloud'"></p></button>
                <button class="btn digital-marketing" @click="component = 'DigitalMarketing'"><p :class="setActiveDigitalMarketing" v-t="'better-future.title-digital-marketing'"></p></button>
            </template>
        </nav>

        <nav class="future-nav" v-if="!isMobile">
            <button class="btn financial-education" @click="component = 'FinancialEducation'"><p :class="setActiveFinancialEducation" v-t="'better-future.title-financial-education'"></p></button>
            <button class="btn health-care" @click="component = 'HealthCare'"><p :class="setActiveHealthCare" v-t="'better-future.title-health-care'"></p></button>
            <button class="btn consulting" @click="component = 'Consult'"><p :class="setActiveConsult" v-t="'better-future.title-consulting'"></p></button>
            <button class="btn cloud" @click="component = 'Cloud'"><p :class="setActiveCloud" v-t="'better-future.title-cloud'"></p></button>
            <button class="btn digital-marketing" @click="component = 'DigitalMarketing'"><p :class="setActiveDigitalMarketing" v-t="'better-future.title-digital-marketing'"></p></button>
        </nav>

        <!--div class="border"></div-->

        <div class="container">
            <component :is="component"></component>
        </div>
    </section>
</template>

<script>
import Brokerage from "./TheFutureModels/Brokerage/BrokerageView";
import Finance from "./TheFutureModels/Finance/FinanceView";
import Wealth from "./TheFutureModels/Wealth/WealthView";
import Payment from "./TheFutureModels/Payment/PaymentView";
import FinancialEducation from "./TheFutureModels/FinancialEducation/FinancialEducationView";
import HealthCare from "./TheFutureModels/HealthCare/HealthCareView";
import Property from "./TheFutureModels/Property/PropertyView";
import Consult from "./TheFutureModels/Consult/ConsultView";
import Cloud from "./TheFutureModels/Cloud/CloudView";
import DigitalMarketing from "./TheFutureModels/DigitalMarketing/DigitalMarketingView";

export default {
    components: {
        Brokerage,
        Finance,
        Wealth,
        Payment,
        FinancialEducation,
        HealthCare,
        Property,
        Consult,
        Cloud,
        DigitalMarketing,
    },
    computed: {
        setActiveBrokerage() {
            return this.component == "Brokerage" ? "active" : "";
        },
        setActiveWealth() {
            return this.component == "Wealth" ? "active" : "";
        },
        setActivePayment() {
            return this.component == "Payment" ? "active" : "";
        },
        setActiveFinance() {
            return this.component == "Finance" ? "active" : "";
        },
        setActiveFinancialEducation() {
            return this.component == "FinancialEducation" ? "active" : "";
        },
        setActiveHealthCare() {
            return this.component == "HealthCare" ? "active" : "";
        },
        setActiveProperty() {
            return this.component == "Property" ? "active" : "";
        },
        setActiveConsult() {
            return this.component == "Consult" ? "active" : "";
        },
        setActiveCloud() {
            return this.component == "Cloud" ? "active" : "";
        },
        setActiveDigitalMarketing() {
            return this.component == "DigitalMarketing" ? "active" : "";
        },
    },
    data() {
        return {
            component: "Brokerage",
            isMobile: false
        };
    },
    methods:{
        checkMobile() {
            if (window.innerWidth <= 834) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        }
    },
    mounted() {
        this.checkMobile()
        window.addEventListener("resize", this.checkMobile);
    }
};
</script>

<style lang="less" scoped>
@import "../../less/mediaQuery.less";

#future {
    @media @mobile-screen {
        padding: 0 16px;
    }

    .header {
        .title {
            font-size: 48px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #3e3a39;
            margin-bottom: 24px;

            @media @mobile-screen {
                margin-bottom: 16px;
                font-size: 28px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: center;
                color: #3e3a39;
            }
        }

        .sub-header {
            max-width: 910px;
            width: 100%;

            margin: auto;

            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #3e3a39;
            margin-bottom: 72px;

            @media @mobile-screen {
                margin-bottom: 24px;
            }
        }
    }

    .future-nav {
        position: relative;
        max-width: 1329px;
        margin: auto;
        padding: 0 16px 14px;
        display: grid;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        gap: 10px;

        &:nth-child(2) {
            margin-top: 64px;
            margin-bottom: 12px;
        }

        &:nth-child(3) {
            margin-bottom: 64px;
        }

        @media @mobile-screen {
            gap: 2%;
            padding: 0 16px 16px;
            margin: 0;
            margin-bottom: 32px !important;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-rows: repeat(4, 1fr);
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            border: 0;
        }

        .btn {
            position: relative;
            background-color: transparent;
            border: none;
            font-size: 20px;
            color: #9e9e9f;
            cursor: pointer;
            font-family: "MicrosoftYaHei";
            padding-top: 24px;
            padding-bottom: 24px;

            @media @mobile-screen {
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                height: 100%;
                display: block;
                width: 100%;
                text-align: center;
                padding-top: 0;
                padding-bottom: 24px;

                &:nth-last-child(1){
                    grid-column: 2 /span 1;
                }
            }

            p{
                margin: 0 30px;

                @media @mobile-screen {
                    margin: 0 14px;
                }
            }
        }

        .btn:hover {
            font-weight: bold;
            color: #e03323;
        }

        .active {
            color: #e03323;
            font-size: 28px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: normal;
            color: #e03323;
            border-bottom: 3px solid #e03323;
            padding: 0 0 20px 0;

            @media @mobile-screen {
                font-family: "MicrosoftYaHei-Bold";
                font-size: 12px;
                padding: 0 0 8px 0;
            }
        }

        // .active::after {
        //     content: url('https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/Close-Red.svg');
        //     position: absolute;
        //     top: initial;
        //     bottom: -29px;
        //     left: 50%;
        //     margin-left: -12px;

        //     @media @mobile-screen {
        //         bottom: -13px;
        //     }
        // }
    }

    .border {
        margin-left: auto;
        margin-right: auto;
        height: 1px;
        background-color: rgba(158, 158, 159, 0.4);
        // margin: auto;

        margin: 0 16px 64px;
        max-width: 1570px;

        @media only screen and (max-width: 1342px) {
            margin: 0 0 32px;
            padding-left: 16px;
            padding-right: 16px;
        }
    }
}

html[lang='en'] #future {

    .future-nav {
        @media screen and (min-width:834px) and (max-width:1084px) {
            font-size: 20px;
        }
    }

}
</style>
