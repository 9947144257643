<template>
    <div class="home-view">
        <LanguageBar v-if="isMobile == false"></LanguageBar>
        <NavigationBar></NavigationBar>
        <router-view />
        <TheFooter></TheFooter>
    </div>
</template>
  
<script>
// @ is an alias to /src
import LanguageBar from '../components/Model/LanguageBar.vue'
import TheFooter from '../components/Model/TheFooter.vue'
import NavigationBar from '../components/Model/NavigationBar.vue'

export default {
    name: 'Root',
    components: {
        LanguageBar,
        TheFooter,
        NavigationBar
    },
    data() {
        return {
            isMobile: false
        }
    },
    methods: {
        checkMobile() {
            if (window.innerWidth <= 835) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        }
    },
    mounted() {
        this.checkMobile()
        window.addEventListener("resize", this.checkMobile);
    }
}
</script>
  
<style>
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    overflow: hidden;
}

body {
    margin: 0;
}

.home-view {
    margin: 0;
    padding: 0;
}
</style>
  
  