<template>
    <div class="content">
        <div class="bg bg1">
            <div class="logo-bg">
                <img :src="`${ImageUrl}HIGHLIGHTS_2003_1_mobile.png`" class="mobile"/>
                <img :src="`${ImageUrl}HIGHLIGHTS_AU.png`" class="logo"/>
            </div>
            <p>{{ t("aufc.AdelaideUnited-2003-1") }}</p>
            <p>{{ t("aufc.AdelaideUnited-2003-2") }}</p>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const ImageUrl = "https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/aufc/"
</script>

<style lang="less" scoped>
    .content {
        .bg1 {
            background: url("https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/aufc/HIGHLIGHTS_2003_1.png") no-repeat;
        }
    }
</style>