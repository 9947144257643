<template>
  <div id="about">
    <div class="sticky-header">
      <LanguageBar v-if="isMobile == false"></LanguageBar>
      <Header />
    </div>
    <div class="padding-sticky-header"></div>
    <Banner />
    <PartnershipMission />
    <div class="grey-bg scrollMargin" id="milestones">
      <div class="center-title">
        <h2>{{ t('mu.first_title') }}</h2>
        <p>{{ t('mu.first_description') }}</p>
      </div>
      <TradingToDreams />
    </div>
    <div id="values" class="scrollMargin">
      <div class="center-title">
        <h2>{{ t('mu.second_title') }}</h2>
        <p>{{ t('mu.second_description') }}</p>
      </div>
      <ThreeImageBanner />
      <CenterBanner />
    </div>
    <div id="highlights" class="scrollMargin">
      <div class="center-title">
        <h2>{{ t('mu.third_title') }}</h2>
        <p>{{ t('mu.third_description') }}</p>
      </div>
      <ThreeBlogRow />
    </div>
  </div>
  <Modals />
</template>
  
<script setup>
import { ref, onMounted, onUnmounted, watch, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
import "../less/style.css"
import LanguageBar from '@/components/Model/LanguageBar.vue'
import Header from "../components/ManUtd/Header"
import Banner from "../components/ManUtd/Banner"
import PartnershipMission from "../components/ManUtd/PartnershipMission"
import TradingToDreams from "../components/ManUtd/TradingToDreams"
import ThreeImageBanner from "../components/ManUtd/ThreeImageBanner"
import ThreeBlogRow from "../components/ManUtd/ThreeBlogRow"
import CenterBanner from "../components/ManUtd/CenterBanner"
import Modals from '@/components/Modals/ModalMain.vue'
import { getLocation } from "@/api/location";
import { useRouter, useRoute } from 'vue-router';

const router = useRouter()
const route = useRoute()

let isMobile = ref(false);
let currentCountry = "";

onMounted(async () => {
  const location = await getLocation();

  let defaultLang = 'en';

  if (location.code == 0) {
    currentCountry = location.data.country_code

    const locationToLang = {
      CN: "sc",
      TW: "tc",
      HK: 'sc',
    };

    const keys = Object.keys(locationToLang);

    if (keys.includes(currentCountry)) {
      defaultLang = locationToLang[currentCountry]
    }
  }

  const checkMobile = () => {
    if (window.innerWidth <= 835) {
      isMobile.value = true;
    } else {
      isMobile.value = false;
    }
  };

  checkMobile(); // Initial check when component is mounted

  window.addEventListener("resize", checkMobile);

  onUnmounted(() => {
    window.removeEventListener("resize", checkMobile);
  });
});

</script>

<style lang="less">
html {
  -scroll-behavior: smooth;
}
</style>
