import { defineStore } from "pinia";

export const useCommonStore = defineStore({
    id: "common",
    state: () => ({
        modal: {
            isOpen: false,
            name: "",
        },
        article: {
            number: "",
            date: ""
        }
    }),
    actions: {
        openModal(name) {
            this.modal.isOpen = true;
            this.modal.name = name;
        },
        closeModal() {
            this.modal.isOpen = false;
            setTimeout(() => {
              this.modal.name = "";
            }, 300);
        },
        setArticle(num, d) {
            this.article.number = num;
            this.article.date = d;

        }
    }
})
