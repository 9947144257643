export function checkBrowser() {
    let browser = "";
    let isMobile = "";
    let isAppleChrome = false;
  
    let userAgent = navigator.userAgent;
    
    if (userAgent.indexOf("Macintosh") > -1 && userAgent.indexOf("Chrome") > -1) {
        console.log("apple Chrome");
        isAppleChrome = true;
    }

    isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    
    
    if (userAgent.indexOf("Firefox") > -1) {
        browser = "Mozilla Firefox";
    } else if (userAgent.indexOf("SamsungBrowser") > -1) {
        browser = "Samsung Internet";
    } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
        browser = "Opera";
    } else if (userAgent.indexOf("Trident") > -1) {
        browser = "Microsoft Internet Explorer";
    } else if (userAgent.indexOf("Edge") > -1) {
        browser = "Microsoft Edge";
    } else if (userAgent.indexOf("Chrome") > -1) {
        browser = "Google Chrome or Chromium";
    } else if (userAgent.indexOf("Safari") > -1) {
        browser = "Apple Safari";
    } else {
        browser = "unknown";
    }
    
    return { isMobile, browser, isAppleChrome };
}