import axios from "axios";

export const getBlogPost = async (lang) => {
  try {
    const params = {
      tags: [302, 281, 303, 188],
      _embed: "",
    };
    const payload = await axios({
      method: "GET",
      url: `https://blog.doo.com/${lang}/wp-json/wp/v2/posts`,
      params: params,
      // withCredentials: true,
    });
    console.log(payload.request.responseURL);
    return payload.data;
  } catch (e) {
    return e;
  }
};
