<template>
    <section id="description-risk-disclosure">
        <p class="description" v-for="item in info?.risk_disclosure.slice(1)">{{ item.translate }}</p>
    </section>
</template>

<script>
export default {
    props: {
        info: [],
    }
}
</script>

<style lang="less" scoped>
section {
    text-align: left;

    .description {
        text-align: left;
        
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #9e9e9f;
        margin-bottom:16px;
    }

    .description:nth-last-child(1) {
        margin-bottom: 0;
        padding-bottom: 40px;
    }
}
</style>