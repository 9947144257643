<template>
    <div class="modal-content MUArticles">
        <img :src=currentImage />
        <div class="content">
            <img :src="RedArrowRight" alt="" class="small-arrow-icon" />
            <div>
                <h3 class="title">{{ t('mu.milestone_title_' + num) }} <span class="date">{{ date }}</span></h3>
                
                <p class="description" v-html="(t('mu.milestone_description_'  + num)).replace(/(\r\n|\n|\r)/gm, '<br/>')"></p>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { useCommonStore } from '@/stores/common'
    import ImageMilestone1 from "@/assets/MuLanding/Image-Milestones1.png";
    import ImageMilestone2 from "@/assets/MuLanding/Image-Milestones2.png";
    import ImageMilestone3 from "@/assets/MuLanding/Image-Milestones3.png";
    import ImageMilestone4 from "@/assets/MuLanding/Image-Milestones4.png";
    import ImageMilestone5 from "@/assets/MuLanding/Image-Milestones5.png";
    import RedArrowRight from "@/assets/MuLanding/icon-arrow-right-red.png";
    import { useI18n } from 'vue-i18n';

    const { t } = useI18n();

    const commonStore = useCommonStore()

    const num = commonStore.article.number;
    const date = commonStore.article.date;

    let currentImage = ImageMilestone1;

    switch(num) {
        case "5":
            currentImage = ImageMilestone5
        break;
        case "4":
            currentImage = ImageMilestone4
        break;
        case "3":
            currentImage = ImageMilestone3
        break;
        case "2":
            currentImage = ImageMilestone2
        break;
        default:
            currentImage = ImageMilestone1
        break;
    }
</script>

<style lang="less">
    .MUArticles {
        > img {
            max-width:100%;
            margin-bottom:16px;
        }
        .content {
            display: flex;
            text-align: left;
            img {
                margin-top: 4px;
                margin-right:16px;
            }
            .title {
                margin-bottom:16px;
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.25;
                letter-spacing: normal;
                text-align: left;
                color: #3e3a39;
                .date {
                    margin-top:8px;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.67;
                    letter-spacing: normal;
                    text-align: left;
                    color: #3e3a39;
                    display:block;
                }
            }
            p {
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.5;
                letter-spacing: normal;
                text-align: left;
                color: #3e3a39;
            }
        }
    }
</style>