<template>
   <Teleport to="head">
      <title>{{$t("seo_title")}} | {{$t("seo_title2")}}</title>
      <meta name="description" :content="$t('seo_desc')"/>
      <meta name="keyword" :content="$t('seo_keyword')"/>
  </Teleport>
  <div class="group-introduction header-gap">
    <div class="sub-banner">
      <div class="container">
        <h1 v-html="$t('about.group-title')"></h1>
        <p v-html="$t('about.group-sub-title')"></p>
      </div>
    </div>
    <div class="better-future">
      <div class="container">
        <h2>{{$t("about.betterFuture")}}</h2>
        <p>{{$t("about.betterFutureDesc")}}</p>
        <div class="videos">
          <iframe :src="locale === 'en' ? 'https://www.youtube.com/embed/PUlY9HnDVfY?rel=0' : 'https://www.youtube.com/embed/qKt3rqdLvVg?rel=0'"
          width="560" height="315" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>
      <img class="web" src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/about/city_new.png">
      <img class="mobile" src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/about/city_mobile_new.png">
    </div>
    <div class="mission">
      <div class="container">
        <div class="content">
          <h2>{{$t("about.mission")}}</h2>
          <p><i></i> {{$t("about.missionDesc")}}</p>
        </div>
        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/about/mission.png" />
      </div>
    </div>
    <div class="vision">
      <div class="container">
        <div class="content">
          <h2>{{$t("about.vision")}}</h2>
          <p><i></i> {{$t("about.visionDesc")}}</p>
        </div>
        <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/about/vision.png" />
      </div>
    </div>
    <div class="values">
      <div class="container">
        <h2>{{$t("about.values")}}</h2>
        <div class="wrapper web">
          <div class="card">
            <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/about/value_1.png" />
            <div class="content">
              <i></i>
              <h3>{{$t("about.values-1")}}</h3>
              <p>{{$t("about.values-1-desc")}}</p>
            </div>
          </div>
          <div class="card">
            <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/about/value_2.png" />
            <div class="content">
              <i></i>
              <h3>{{$t("about.values-2")}}</h3>
              <p>{{$t("about.values-2-desc")}}</p>
            </div>
          </div>
          <div class="card">
            <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/about/value_3.png" />
            <div class="content">
              <i></i>
              <h3>{{$t("about.values-3")}}</h3>
              <p>{{$t("about.values-3-desc")}}</p>
            </div>
          </div>
        </div>
        <div class="carousel mobile">
          <carousel :items-to-show="1" :autoplay="5000" :wrap-around="true">
            <slide key="1">
              <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/about/value_1.png" />
              <div class="content">
                <i></i>
                <h3>{{$t("about.values-1")}}</h3>
                <p>{{$t("about.values-1-desc")}}</p>
              </div>
            </slide>
            <slide key="2">
              <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/about/value_2.png" />
              <div class="content">
                <i></i>
                <h3>{{$t("about.values-2")}}</h3>
                <p>{{$t("about.values-2-desc")}}</p>
              </div>
            </slide>
            <slide key="3">
              <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/about/value_3.png" />
              <div class="content">
                <i></i>
                <h3>{{$t("about.values-3")}}</h3>
                <p>{{$t("about.values-3-desc")}}</p>
              </div>
            </slide>
            <template #addons>
              <pagination />
            </template>
          </carousel>
        </div>
      </div>
    </div>
    <div class="business-and-brands">
      <div class="container">
        <h2>{{$t("about.businessAndBrands")}}</h2>
        <p>{{$t("about.businessAndBrandsDesc")}}</p>
        <div class="wrapper">
          <div class="card">
            <h3>{{$t("about.brokerage")}}</h3>
            <div class="sub-brands">
              <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/icon-dc.svg" class="dc"/>
              <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/icon-df.svg" class="df"/>
            </div>
          </div>
          <div class="card">
            <h3>{{$t("about.wealthManagement")}}</h3>
            <div class="sub-brands">
              <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/about/new-milestone/sub-brand_DW_new.png" class="dw"/>
            </div>
          </div>
          <div class="card">
            <h3>{{$t("better-future.title-property")}}</h3>
            <div class="sub-brands">
              <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/doo-property-logo.png" alt="Doo Property" class="dpr">
            </div>
          </div>
          <div class="card">
            <h3>{{$t("about.paymentExchange")}}</h3>
            <div class="sub-brands">
              <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/DP-Colour-Dark-BG.svg" class="dpe"/>
              <!--img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/about/logo-sub-brand-dpe-colour-light-bg@2x.png" class="dpe"/-->
              <!--img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/about/logo-sub-brand-lp-colour-light-bg@2x.png" class="lp"/ -->

            </div>
          </div>
          <div class="card">
            <h3>{{$t("about.financialTechnology")}}</h3>
            <div class="sub-brands">
              <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/about/new-milestone/sub-brand_FP_new.png" class="fp"/>
            </div>
          </div>
          <div class="card">
            <h3>{{$t("better-future.title-financial-education")}}</h3>
            <div class="sub-brands">
              <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/DA-Colour-Light-BG.svg" alt="financial education" class="da">
            </div>
          </div>
          <div class="card">
            <h3>{{$t("better-future.title-health-care")}}</h3>
            <div class="sub-brands">
              <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/DC-Colour-Dark_BG.svg" alt="dh" class="dh">
            </div>
          </div>
          <div class="card">
            <h3>{{$t("better-future.title-consulting")}}</h3>
            <div class="sub-brands">
              <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/doo-consulting-logo.png" alt="Doo Consulting" class="dcs">
            </div>
          </div>
          <div class="card">
            <h3>{{$t("better-future.title-cloud")}}</h3>
            <div class="sub-brands">
              <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/doo-cloud-logo.png" alt="Doo Cloud" class="dcd">
            </div>
          </div>
          <div class="card">
            <h3>{{$t("better-future.title-digital-marketing")}}</h3>
            <div class="sub-brands">
              <img src="https://doo-prime-static.oss-cn-hongkong.aliyuncs.com/doo/Home/sub-brand/doo-digital-logo.png" alt="Doo Digital Plus" class="ddp">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
  import { useI18n } from "vue-i18n";
  import { onMounted } from 'vue'

  const { locale } = useI18n();

  // onMounted(() => {
  //   const videoPopupOverlay = document.getElementById('video-popup-overlay');
  //   const videoPopupIframeContainer = document.getElementById('video-popup-iframe-container');
  //   const videoPopupContainer = document.getElementById('video-popup-container');
  //   const videoPopupClose = document.getElementById('video-popup-close');
  //   const videoPopupIframe = document.getElementById('video-popup-iframe');
  //   const vpop = document.querySelector('.vpop');

  //   const openVideoPopup = (e) => {
  //     e.preventDefault();

  //     videoPopupOverlay.style.display = 'block';
  //     videoPopupIframeContainer.style.display = 'block';
  //     videoPopupContainer.style.display = 'flex';
  //     videoPopupClose.style.display = 'block';

  //     videoPopupIframe.src = 'https://www.youtube.com/embed/yWW81XAr9iI?autoplay=1';

  //   };

  //   const closeVideoPopup = () => {
  //     videoPopupIframeContainer.style.display = 'none';
  //     videoPopupContainer.style.display = 'none';
  //     videoPopupClose.style.display = 'none';
  //     videoPopupOverlay.style.display = 'none';
  //     videoPopupIframe.src = '';
  //   };

  //   const vpopElements = () => {
  //     vpop.addEventListener('click', openVideoPopup);

  //     const closePopupElements = document.querySelectorAll('#video-popup-close, #video-popup-overlay');
  //     closePopupElements.forEach((element) => {
  //       element.addEventListener('click', closeVideoPopup);
  //     });
  //   };

  //   vpopElements();

  //   return {
  //     openVideoPopup,
  //     closeVideoPopup,
  //     vpopElements,
  //   };
  // })
</script>

<style lang="less">
@import '../less/mediaQuery.less';
.group-introduction {
  i {
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 10px solid #e03323;
    display: inline-block;
    vertical-align: 0px;
    margin-right:16px;
    position: absolute;
    left: 0;
    top: 6px;
  }
  .sub-banner {
    background:url('https://cdn.dootech.io/doo/about/group_introduction.png') no-repeat 20% top;
    @media @mobile-screen {
      background:url('https://cdn.dootech.io/doo/about/group_introduction_mobile.png') no-repeat center top;
    }
    .container {
      @media only screen and (max-width:1500px) and (min-width:835px) {
        max-width: 40%;
        margin: 0;
      }
      @media only screen and (min-width:1921px) {
        h1, p {
          max-width: 342px;
        }
      }
    }
  }
  .container {
    padding:0 16px;
    
  }
  .better-future {
    padding-bottom:160px;
    @media @mobile-screen {
      padding-bottom:80px;
    }
    h2 {
      font-size: 48px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #3e3a39;
      margin-bottom:24px;
      @media @mobile-screen {
        font-size: 28px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #3e3a39;
        margin-bottom:16px;
      }
    }
    p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #3e3a39;
      max-width:788px;
      margin:0 auto 24px;
      @media @mobile-screen {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #3e3a39;
      }
    }
    img {
      max-width: 100%;
      width: 100%;
      height: 450px;
      object-fit: cover;
      @media @mobile-screen {
        height: 180px;
        object-fit: cover;
      }
    }
    .web {
      margin-top:-250px;
      position: relative;
      z-index: -1;
    }
    .mobile {
      margin-top:-90px;
      position: relative;
      z-index: -1;
    }
  }
  .mission, .vision {
    padding-bottom:160px;
    @media only screen and (max-width:1000px)  {
      padding-bottom:80px;
    }
    .container {
      display:flex;
      justify-content: center;
      align-items: center;
      text-align: left;
      @media only screen and (max-width:679px) {
        flex-direction: column;
      }
      .content {
        @media only screen and (min-width:680px) and (max-width:1000px){
          padding: 0 16px;
        }
      }
      > img {
        height:450px;
        max-width:100%;
        @media only screen and (min-width:680px) and (max-width:1000px){
          height: auto;
          max-width: 50%;
        }
        @media only screen and (max-width:679px) {
          height: auto;
          width: 100%;
        }
      }
    }
    .content {
      h2 {
        font-size: 48px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #3e3a39;
        margin-bottom:24px;
        @media only screen and (max-width:1000px)  {
          font-size: 28px;
        }
        @media only screen and (max-width:679px) {
          text-align: center;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: center;
          color: #3e3a39;
          margin-bottom:16px;
        }
      }
      p {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #3e3a39;
        position: relative;
        @media only screen and (min-width:680px) {
          padding-left: 26px;
        }
        @media only screen and (max-width:679px) {
          text-align: center;
          margin-bottom:40px;
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: center;
          color: #3e3a39;
        }
        i {
          @media only screen and (max-width:679px) {
            display:none;
          }
        }
      }
    }
  }
  .mission {
    .container {
      > img {
        @media only screen and (min-width:1001px) {
          order: 1;
          margin-right:126px;
        }
      }
    }
    .content {
      @media only screen and (min-width:680px) {
        order: 2;
      }
    }
  }
  .vision {
    .content {
      @media only screen and (min-width:1001px) {
        margin-right:126px;
      }
    }
  }
  .values {
    padding-bottom:160px;
    @media @mobile-screen {
      padding-bottom:80px;
    }
    h2 {
      font-size: 48px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #3e3a39;
      margin-bottom:80px;
      @media @mobile-screen {
        font-size: 28px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #3e3a39;
        margin-bottom:40px;
      }
    }
    .wrapper {
      max-width: 1330px;
      margin: 0 auto;
      display:flex;
      gap:24px;
      .card {
        width:33.3%;
        background: #f8f8f8;
      }
    }
    .content {
        position:relative;
        text-align: left;
        padding:32px 32px 40px 56px;
        @media @mobile-screen {
          padding-top:24px;
          padding-left: 45px;
          padding-right:16px;
        }
        i {
          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 8px solid #e03323;
          left: 26px;
          top: 49px;
          @media @mobile-screen {
            left: 18px;
            top: 33px;
          }
        }
      }
      img {
        max-width:100%;
        @media @mobile-screen {
          width:100%;
        }
      }
      h3 {
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #3e3a39;
        margin-bottom:16px;
        @media @mobile-screen {
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: normal;
          color: #3e3a39;
          margin-bottom:8px;
        }
      }
      p {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #3e3a39;
      }
    .carousel__slide {
      flex-direction: column;
      justify-content: flex-start;
      background: #f8f8f8;
      h3 {
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        color: #3e3a39;
      }
      p {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #3e3a39;
      }
    }
    .carousel__pagination-button::after {
      width:6px;
      height:6px;
      background:#9e9e9f;
    }
    .carousel__pagination-button--active::after {
      background:#e03323;
    }
    .carousel__pagination {
      margin-top:32px;
    }
  }
  .business-and-brands {
    background:url('https://cdn.dootech.io/doo/about/brand_g.png') no-repeat center top;
    background-size:cover;
    padding: 160px 0;
    @media @mobile-screen {
      padding: 80px 0;
    }
    h2 {
      font-size: 48px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #3e3a39;
      margin-bottom:24px;
      @media @mobile-screen {
        font-size: 28px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #3e3a39;
        margin-bottom:16px;
      }
    }
    p {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #3e3a39;
      margin: 0 auto 80px;
      max-width: 1059px;
      @media @mobile-screen {
        margin-bottom:33px;
      }
    }
    .wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      gap: 24px;
      max-width: 1329px;
      margin: 0 auto;

      @media only screen and (min-width:1600px) {
        grid-template-columns: repeat(4, 1fr);
      }

      @media only screen and (max-width:1000px) {
        display:block;
      }
      .card {
        max-width:427px;
        width:100%;
        padding:28px 0 32px;
        border-top:4px solid #e03323;
        background-color: #fff;
        @media only screen and (max-width:1000px) and (min-width:680px) {
          display:inline-block;
          vertical-align: top;
          width: 46%;
          margin:12px 12px;
        }
        @media only screen and (min-width: 1600px){
          &:nth-last-child(2){
            grid-column: 2 / span 1;
          }  
        }
        @media only screen and (min-width:835px) {
          min-height: 278px;
        }
        @media @mobile-screen {
          border-top:3px solid #e03323;
          display: flex;
          flex-direction: column;
          padding: 24px 30px 40px;
          max-width:314px;
          width:100%;
          display: inline-block;
          margin: 0 8px 16px;
        }
      }
      .sub-brands {
        display:flex;
        justify-content:space-evenly;
        align-items: center;
        flex-direction: column;
        height: 100%;
        height: calc(100% - 31px);
        @media only screen and (min-width:692px) {
          min-height: 185px;
        }
      }
      h3 {
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #3e3a39;
        margin-bottom:6.3px;

        @media @mobile-screen {
          font-size: 20px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.4;
          letter-spacing: normal;
          color: #3e3a39;
          margin-bottom:0;
        }
      }
      img {
        display:block;
        width:100%;
        padding: 14px 0;
        &:last-child {
          margin-bottom:0;
        }
        &.dc{max-width: 189px;}
        &.df{max-width: 226px;}
        &.dp{max-width: 148px;}
        &.dw{max-width: 250px;}
        &.dpe{max-width: 184px;}
        &.lp{max-width: 142px;}
        &.fp{max-width: 182px;}
        &.da{max-width: 179px;}
        &.dh{max-width: 221px;}
        &.dpr{max-width: 233px;}
        &.dcs{max-width: 253px;}
        &.dcd{max-width: 226px;}
        &.ddp{max-width: 156px;}
        
        @media @mobile-screen {
          &:first-of-type {
            margin-top:32px;
          }
          &.dc{max-width: 140px;}
          &.df{max-width: 204px;}
          &.dp{max-width: 132px;}
          &.dw{max-width: 175px;}
          &.dpe{max-width: 142px;}
          &.lp{max-width: 118px;}
          &.fp{max-width: 140px;}
          &.da{max-width: 128px;}
          &.dh{max-width: 158px;}
          &.dpr{max-width: 163px;}
          &.dcs{max-width: 185px;}
          &.dcd{max-width: 164px;}
          &.ddp{max-width: 112px;}
        }
      }
    }
  }
  .videos {
    display: flex;
    justify-content: center;
    gap: 24px;
    @media @mobile-screen {
      display:block;
    }
    iframe {
      max-width: 1058px;
      width: 100%;
      height: 595px;
    }
  }
}
#footer {
  margin-top:0 !important;
}

</style>