<template>
    <section id="opportunity">
        <p class="title" v-t="'future-opportunity.title'"></p>
        <div class="sub-title" v-t="'future-opportunity.sub-title'"></div>
        <div class="contact-us-container">
            <button class="btn-contact" @click="openModalContactForm" v-t="'future-opportunity.contact-us'">
            </button>
        </div>
    </section>
</template>

<script setup>
    import { useCommonStore } from '@/stores/common'

    const commonStore = useCommonStore()

    const openModalContactForm = () => {
        console.log("click");
        commonStore.openModal('ContactForm')
    }

</script>

<style lang="less" scoped>
@import '../../less/mediaQuery.less';
#opportunity {
    margin-top:160px;
    padding:0 16px;
    @media @mobile-screen {
        padding: 0 16px;
    }
    .title {
        max-width: 863px;
        width: 100%;
        padding-bottom: 24px;
        margin: auto;
        
        font-size: 48px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #3e3a39;

        @media @mobile-screen {
            font-size: 28px;
            padding-top: 80px;
            margin-bottom: 16px
        }
    }

    .sub-title {
        margin-bottom: 40px;
        
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #3e3a39;
    }

    .contact-us-container {
        .btn-contact {
            
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: #fff;
            white-space: nowrap;

            border: none;
            width: 150px;
            height: 48px;
            margin: 0px auto 160px;
            padding: 12px 16px;
            background-color: #e03323;

            @media @mobile-screen {
                margin-bottom:80px;
            }
        }
    }
}
</style>