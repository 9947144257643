import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from "pinia"
import i18n from './i18n'
import { plugin, defaultConfig } from '@formkit/vue'
import './less/main.less'
import 'vue3-carousel/dist/carousel.css'
import Vue3Lottie from 'vue3-lottie'
import * as Sentry from "@sentry/vue";
import { SENTRY_PROID, SENTRY_PROKEY, SENTRY_TAG , SENTRY_ENV } from "./config";

const domain = window.location.hostname;
const app = createApp(App)
Sentry.init({
  app: app,
  dsn: `https://${SENTRY_PROKEY}@${domain}/sentry/${SENTRY_PROID}`,
  release: SENTRY_TAG,
  environment: SENTRY_ENV,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    })
  ],
  debug:false,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
app.use(i18n)
app.use(router)
app.use(createPinia())
app.use(plugin, defaultConfig )
app.use(Vue3Lottie)
app.mount('#app')

