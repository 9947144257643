<template>
    <div class="tag">
        <h3>{{ t("about.brand") }}</h3>
        <p>{{t('about.m-2021-b1')}}</p>
        <p>{{t('about.m-2021-b2')}}</p>
        <p>{{t('about.m-2021-b3')}}</p>
        <p>{{t('about.m-2021-b4')}}</p>
        <p>{{t('about.m-2021-b5')}}</p>
        <p>{{t('about.m-2021-b6')}}</p>
        <p>{{t('about.m-2021-b7')}}</p>
        <p>{{t('about.m-2021-b8')}}</p>
        <p>{{t('about.m-2021-b9')}}</p>
        <!-- <p v-if="locale !== 'tc'">{{t('about.m-2021-b10')}}</p> -->
    </div>
    <div class="tag">
        <h3>{{ t("about.global") }}</h3>
        <!--p>{{t('about.m-2021-g1')}}</p-->
        <p>{{t('about.m-2021-g2')}}</p>
    </div>
    <div class="tag">
        <h3>{{ t("about.safety") }}</h3>
        <p>{{t('about.m-2021-s1')}}</p>
        <!-- <p>{{t('about.m-2021-s2')}}</p> -->
    </div>
    <!--div class="tag">
        <h3>{{ t("about.product") }}</h3>
        <p>{{t('about.m-2021-p1')}}</p>
        <p>{{t('about.m-2021-p2')}}</p>
        <p>{{t('about.m-2021-p3')}}</p>
        <p>{{t('about.m-2021-p4')}}</p>
        <p>{{t('about.m-2021-p5')}}</p>
        <p>{{t('about.m-2021-p6')}}</p>
    </div-->
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t,locale } = useI18n();
</script>
