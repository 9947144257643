<template>
  <div class="home-view">
    <router-view/>
  </div>
</template>

<script>
import AOS from "aos";
import "aos/dist/aos.css";
import { SENTRY_PROID, SENTRY_PROKEY } from "./config";

export default {
  name: 'App',
  data(){
    return{
      sentryData: {
        product: 'doo-group',
        proKey: SENTRY_PROKEY,
        proId: SENTRY_PROID,
      },
    }
  },
  methods: {
    loadSentryScript() {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'sentry-health-track';
      script.src = '/healthCheck.js';
      script.setAttribute('data-track-config', JSON.stringify(this.sentryData));
      document.body.appendChild(script);
    },
  },
  beforeMount(){
    this.loadSentryScript();
  },
  mounted() {
    AOS.init();
  }
}
</script>

<style>
.home-view{
  position: relative;
}
</style>

