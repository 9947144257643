import axios from "axios";
import { BASE_URL } from "@/config";

export const getHCaptachaKey = async () => {
  try {
    const res = await axios({
      method: "get",
      url: `${BASE_URL || "/api/v1"}/public/getHCaptchaKey`,
    });
    return res.data;
  } catch (error) {
    return false;
  }
};

export const getFooter = async (locale) => {
  try {
    const res = await axios({
      method: "get",
      url: `${BASE_URL || "/api/v1"}/public/getFooter?entities=doogroup&country_code=my&lang=${locale}&footer_menu=1&compliance=1&dynamic_url=1`,
    });
    return res.data;
  } catch (error) {
    return false;
  }
};

export const sendEmail = async (token, data) => {
  try {
    const res = await axios({
      method: "post",
      url: `${BASE_URL || "/api/v1"}/public/sendEmail?type=doo&token=${token}`,
      data: data,
    });
    return res.data;
  } catch (error) {
    console.log("🚀 ~ file: publicCms.js:34 ~ sendEmail ~ error:", error);
    return false;
  }
};
