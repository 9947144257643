<template>
    <div class="disclosure-email">
        <p>
            This email along with its information and/or attachment(s) is confidential and  contains proprietary,
            business-confidential and/or privileged material. If you are not the intended recipient of this email, you are
            hereby notified that (a) any use, dissemination, distribution, reproduction, retransmission; and (b) any action
            taken in reliance; of this email, including information or material, herein is strictly prohibited. If you have
            received this email in error, please notify us immediately and take all necessary steps to delete it from any
            transient or permanent storage device or medium without copying or disclosing any information. We reserve our
            right to take legal actions if necessary. We do all we can to warrant that the information, material and/or
            attachment(s) herein is free from viruses, bugs or anything with destructive properties. Therefore, we shall not
            be liable for any direct, indirect, consequential loss or damages from the use of information, material and/or
            attachment(s) herein.
        </p>
        <div class="rights-reserve">
            <p>
                DOO GROUP © ALL RIGHTS RESERVED
            </p>
        </div>
        <div>
            <img style="height:37px;" src="http://doo-prime-static.oss-cn-hongkong.aliyuncs.com/email-signature-template/brand_logo/DG@3x.png" alt="doo logo">
        </div>
    </div>
</template>

<style scoped>
.disclosure-email {
    color: rgb(181, 181, 182);
    line-height: 18px;
    font-size: 12px;
    font-family: Verdana, sans-serif;
    text-align: left;
}

.rights-reserve {
    padding: 20px 0;
}
</style>